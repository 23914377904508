import * as React from "react";
import {
  PlasmicDcfMeasureRow,
  DefaultDcfMeasureRowProps
} from "./plasmic/imbas_dcf_seiten/PlasmicDcfMeasureRow";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { components } from "../api/types";


export type RENA = components["schemas"]["RENA"];
export interface DcfMeasureRowProps extends DefaultDcfMeasureRowProps {
  index: number
}

function DcfMeasureRow_(
  { index, ...props }: DcfMeasureRowProps,
  ref: HTMLElementRefOf<"div">
) {
  
  return (
    <PlasmicDcfMeasureRow 
      root={{ ref }} 
      {...props} 
      index={index + 1}
      objects={ props.objects }
      deleteButton={{ onClick: props.onDelete }}
      rena={{
        name: `Massnahmen.[${index}].RENA`
      }}
      zeitraum={{
        name: `Massnahmen.[${index}].Zeitraum`
      }}
      investitionsbetrag={{
        name: `Massnahmen.[${index}].Investitionsbetrag`
      }}
      objekte={{
        name: `Massnahmen.[${index}].Objekte`
      }}
      ertragsausfall={{
        name: `Massnahmen.[${index}].Ertragsausfall`
      }}
    />
  );
}

const DcfMeasureRow = React.forwardRef(DcfMeasureRow_);
export default DcfMeasureRow;
