import * as React from "react";
import {
  PlasmicAddressSuggestor,
  DefaultAddressSuggestorProps
} from "./plasmic/imbas_23_fpre/PlasmicAddressSuggestor";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { RenderControlContext } from "../utils";

export interface AddressSuggestorProps extends DefaultAddressSuggestorProps {}

function AddressSuggestor_(
  props: AddressSuggestorProps,
  ref: HTMLElementRefOf<"div">
) {

    return props.isShownInitially && props.hideDetails ? (
      <PlasmicAddressSuggestor
        root={{ ref }} 
        {...props} 
        search={{
          isShownInitially: true,
          hasNoLabel: true
        }}
      />
    ) : (
      <RenderControlContext.Consumer>
        {({ hidden }) => !hidden && <PlasmicAddressSuggestor root={{ ref }} {...props} />}
      </RenderControlContext.Consumer>
    );
}

const AddressSuggestor = React.forwardRef(AddressSuggestor_);
export default AddressSuggestor;
