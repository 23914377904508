// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import {
  PlasmicDcf020ErtragLeerstand,
  DefaultDcf020ErtragLeerstandProps
} from "./plasmic/imbas_dcf_seiten/PlasmicDcf020ErtragLeerstand";
import { HTMLElementRefOf } from "@plasmicapp/react-web";

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface Dcf020ErtragLeerstandProps extends Omit<DefaultDcf020ErtragLeerstandProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultDcf020ErtragLeerstandProps altogether and have
// total control over the props for your component.
export interface Dcf020ErtragLeerstandProps
  extends DefaultDcf020ErtragLeerstandProps {}

function Dcf020ErtragLeerstand_(
  props: Dcf020ErtragLeerstandProps,
  ref: HTMLElementRefOf<"div">
) {
  // Use PlasmicDcf020ErtragLeerstand to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicDcf020ErtragLeerstand are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all Dcf020ErtragLeerstandProps here, but feel free
  // to do whatever works for you.

  return <PlasmicDcf020ErtragLeerstand root={{ ref }} {...props} />;
}

const Dcf020ErtragLeerstand = React.forwardRef(Dcf020ErtragLeerstand_);
export default Dcf020ErtragLeerstand;
