import * as React from "react";
import {
  PlasmicDcfMeasureGroup,
  DefaultDcfMeasureGroupProps
} from "./plasmic/imbas_dcf_seiten/PlasmicDcfMeasureGroup";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { useFieldArray, useFormContext } from "react-hook-form";
import DcfMeasureRow from "./DcfMeasureRow";
import { components } from "../api/types";

export type DcfMassnahme = components["schemas"]["ImbasDcfMassnahme"];
export interface DcfMeasureGroupProps 
  extends DefaultDcfMeasureGroupProps {
    measureFactory: (value: number) => object
}

function DcfMeasureGroup_(
  { measureFactory, ...props }: DcfMeasureGroupProps,
  ref: HTMLElementRefOf<"div">
) {

    const maxItems = 10;
    const { control } = useFormContext();
    const { fields, append, remove } = useFieldArray({
      control,
      name: "Massnahmen"
    });
  
  return (
    <PlasmicDcfMeasureGroup 
      root={{ ref }} 
      {...props} 
      items={
         fields.map((item, index) => {
          const currentKey = index;
          return (
            <DcfMeasureRow
              key={item.id}
              index={currentKey}
              onDelete={() => remove(index)}
              objects={ props.objects }
            />
          )
        })
      }
      addMeasure={{
        name: "RENA",
        isDisabled: fields.length >= maxItems,
        onChange: (value) => {
          const newMeasure = measureFactory(value as number);
          append(newMeasure);
        }
      }}
    />
  );
}

const DcfMeasureGroup = React.forwardRef(DcfMeasureGroup_);
export default DcfMeasureGroup;
