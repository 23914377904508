import * as React from "react";
import {
  PlasmicDeEwgHerleitungNhk,
  DefaultDeEwgHerleitungNhkProps
} from "./plasmic/imbas_marktwert_seiten/PlasmicDeEwgHerleitungNhk";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { AutoSavingFormWrapper } from "./custom/AutoSavingFormWrapper";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApplicationRoutes } from "../ApplicationRoutes";
export interface DeEwgHerleitungNhkProps
  extends DefaultDeEwgHerleitungNhkProps {}

function DeEwgHerleitungNhk_(
  props: DeEwgHerleitungNhkProps,
  ref: HTMLElementRefOf<"div">
) {
    const { objectId = "plasmic" } = useParams();
    
    const navigate = useNavigate();
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])
    
    
    return (

    <AutoSavingFormWrapper
      className=""  
    >
      <PlasmicDeEwgHerleitungNhk 
        root={{ ref }} 
        {...props} 
        backButton={{
          onClick: () => {
          objectId ?                  
            navigate(
              `${ApplicationRoutes.getPath("ewg020Marktwert", { objectId: objectId ?? "" })}#Marktwert/Sachwert`, 
              { state: {  cardId:"Marktwert/Sachwert", isClosed: false } }
            ) : navigate(-1);
          }
        }}
      />
    </AutoSavingFormWrapper>
    );
}

const DeEwgHerleitungNhk = React.forwardRef(DeEwgHerleitungNhk_);
export default DeEwgHerleitungNhk;
