import {
  AuthenticationResult,
  EventMessage,
  EventType,
  PublicClientApplication
} from "@azure/msal-browser";
import { toast } from "react-toastify";
import { t } from "i18next";

const msalInstance = new PublicClientApplication({
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID!,
    authority: process.env.REACT_APP_AZURE_AUTHORITY,
    knownAuthorities: [process.env.REACT_APP_AZURE_KNOWNAUTHORITY ?? ''],
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    //https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/caching.md
    cacheLocation: process.env.REACT_APP_MSAL_CACHELOCATION,
    storeAuthStateInCookie: false
  },
  system: {
    iframeHashTimeout: 10000,
  },
});

export const msalEventCallback = ({ eventType, payload }: EventMessage) => {
  const isLoginEvent = eventType === EventType.LOGIN_SUCCESS;
  const isAcquireTokenEvent = eventType === EventType.ACQUIRE_TOKEN_SUCCESS;

  if (isLoginEvent || isAcquireTokenEvent) {
    msalInstance.setActiveAccount((payload as AuthenticationResult).account);

    if (isLoginEvent) {
      toast.info(t("toast.sign_in_success"), { toastId: "login-success" });
    }
  }
};

// The default scopes `openid`, `offline_access` and `profile` are automatically added by MSAL
export const loginRequest = {
  scopes: [process.env.REACT_APP_AZURE_FRONTENDSCOPE ?? ''],
};

export const acquireTokenRequest = {
  scopes: [process.env.REACT_APP_AZURE_BACKENDSCOPE ?? ''],
  authority: process.env.REACT_APP_AZURE_AUTHORITY,
  forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
};

export const acquireTokenSilentRequest = {
  scopes: [process.env.REACT_APP_AZURE_BACKENDSCOPE ?? ''],
  authority: process.env.REACT_APP_AZURE_AUTHORITY,
  forceRefresh: true,
  refreshTokenExpirationOffsetSeconds: 86400
};


export default msalInstance;
