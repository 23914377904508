import * as React from "react";
import {
  PlasmicDcfObjectGroup,
  DefaultDcfObjectGroupProps
} from "./plasmic/imbas_dcf_seiten/PlasmicDcfObjectGroup";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useCallback, useMemo, useState } from "react";
import DcfObjectRow from "./DcfObjectRow";
import { DcfObjekt, getGWRObjects } from "../api/endpoints/dcf";

export interface DcfObjectGroupProps extends DefaultDcfObjectGroupProps {
  objectFactory: (referenz: string, egid: number | null, adresse: string | null, baujahr: number | null) => object
  objekte: DcfObjekt[] | null | undefined
}

function DcfObjectGroup_(
  { objectFactory, objekte, ...props }: DcfObjectGroupProps,
  ref: HTMLElementRefOf<"div">
) {
    const name = "Objekte";
    const { control, getValues } = useFormContext();
    const { fields, append, remove } = useFieldArray({ control, name });
    const [gwrObjectLoading, setGwrObjectLoading] = useState(false)

    const handleDelete = useCallback((index: number) => {
        remove(index)
    }, [getValues, remove]);
  
    const items = useMemo(() => fields.map((field, index) => {
      const adresse = (objekte && objekte[index] ? objekte[index].Adresse ?? undefined : undefined);

      return (
        <DcfObjectRow
          key={field.id}
          index={index}
          onDelete={() => handleDelete(index)}
          adresse={adresse}
        />
      );
    }), [fields, name, handleDelete, objekte]);

    return (
      <PlasmicDcfObjectGroup 
        root={{ ref }} 
        {...props} 
        items={items}
        addButton={{
          onClick: () => {
            append(objectFactory(`Referenz ${items.length + 1}`, null, null, null))
          }
        }}
        objectsFromGwrButton={{
          isLoading: gwrObjectLoading,
          onClick: ()=> {
            setGwrObjectLoading(true);
            getGWRObjects(props.egrids)
              .then(({ data }) => {
                data.map((o, index) => append(
                  objectFactory(
                    o.Referenz ?? `Referenz ${index + 1}`, 
                    o.EGID ?? null, 
                    o.Adresse ?? null, 
                    o.Baujahr ?? null)
                  ));
                setGwrObjectLoading(false);
              });
          }
        }}
      />
    );
}

const DcfObjectGroup = React.forwardRef(DcfObjectGroup_);
export default DcfObjectGroup;
