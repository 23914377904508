import * as React from "react";
import {
  PlasmicDcf011DetailseiteParzelle,
  DefaultDcf011DetailseiteParzelleProps,
  PlasmicDcf011DetailseiteParzelle__OverridesType
} from "./plasmic/imbas_dcf_seiten/PlasmicDcf011DetailseiteParzelle";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { EWGParameters, withCurrentEWGParameters } from "../withCurrentEWGParameters";
import { useRegionContext } from "../context/RegionContext";
import { flow } from "lodash";
import withInitialResourceLoad from "../store/withInitialResourceLoad";
import { 
  getDcfParzelleInput,
  saveDcfParzelleDraft,
  saveDcfParzelleInput,
  getDcfParzelleMod } from "../api/endpoints/dcf";
import { withAutosaveProvider } from "../store/AutosaveProvider";
import { DataStoreName, useDataStore, withDataStoreProvider } from "../store/DataStoreProvider";
import { createElement, useCallback, useEffect, useRef } from "react";
import { AutoSavingFormWrapper } from "./custom/AutoSavingFormWrapper";
import { FormWrapperProps } from "./custom/FormWrapper";
import { useNavigate, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { isErrorResponse, processErrorResponse } from "../api/apiClient";
import { toast } from "react-toastify";
import { t } from "i18next";
import { DcfParzelle } from "./DcfPlotGroup";
import { ApplicationRoutes } from "../ApplicationRoutes";


type ApiErrors = FormWrapperProps<DcfParzelle>["apiErrors"];
export interface Dcf011DetailseiteParzelleProps
  extends DefaultDcf011DetailseiteParzelleProps, EWGParameters {}

function Dcf011DetailseiteParzelle_(
  { objectId, ...props }: Dcf011DetailseiteParzelleProps,
  ref: HTMLElementRefOf<"div">
) {
  const { location } = useRegionContext();
  const { dataStore: { dcfParzelle } } = useDataStore();
  const [apiErrors, setApiErrors] = React.useState<ApiErrors>();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(Boolean(false));
  const [isLoadingNext, setIsLoadingNext] = React.useState<boolean>(Boolean(false));
  const [hasNextParzelle, setHasNextParzelle] = React.useState<boolean>(Boolean(false));
  const [nextParzelleReferenz, setNextParzelleReferenz] = React.useState<string>(t('Details') ?? '');
  const { i18n } = useTranslation();
  const submitActionRef = useRef<"navigate" | "next" | null>(null);

  if(location?.WGS84Lat == null || location?.WGS84Lng == null)
  {
    toast.error(t("toast.missing_coordinates"), { toastId: "validation_error" });
  }

  const onSubmit = useCallback(async (data: DcfParzelle) => {
    try {
      await saveDcfParzelleInput(objectId, data.Referenz!, data);
        
      if (submitActionRef.current === "next") {
          setIsLoadingNext(true);
          if (data.ReferenzNextParzelle) {
            navigate(ApplicationRoutes.getPath(
              "dcf011DetailsParzelle", 
              { objectId, parzelle: data.ReferenzNextParzelle! })
            );              
          }
          if (data.ReferenzFirstObjekt) {
            navigate(ApplicationRoutes.getPath(
              "dcf012DetailsObjekt", 
              { objectId, objekt: data.ReferenzFirstObjekt ?? '' })
            );
          }
      }
      if (submitActionRef.current === "navigate") {
        setIsSubmitting(true);
        navigate(ApplicationRoutes.getPath("dcf020ErtragLeerstand", { objectId }));
      }
    } catch (err) {
      setIsSubmitting(false);
      if (isErrorResponse(err)) {
        setApiErrors(processErrorResponse(err.response.data, i18n.language));
        toast.error(t("toast.validation_error"), { toastId: "validation_error" });
      }
    }
  }, [navigate, objectId]);

  const specificComponentAdjustments: PlasmicDcf011DetailseiteParzelle__OverridesType = {
      submitButton: {
        isLoading: isSubmitting,
        onClick: () => submitActionRef.current = "navigate"
      },
      nextParzelleDetailButton: {
        render: (props, Component) => (
          hasNextParzelle &&
          <Component
            {...props} 
            text={`${t('Details')} "${nextParzelleReferenz}"`} 
            onClick={()=> submitActionRef.current = "next"} 
            isLoading={isLoadingNext}
          />
        )
      }
  };
  
  useEffect(() => {
      if (dcfParzelle) {
        setHasNextParzelle(dcfParzelle.ReferenzNextParzelle != null || dcfParzelle.ReferenzFirstObjekt != null)
        setNextParzelleReferenz(dcfParzelle.ReferenzNextParzelle ?? dcfParzelle.ReferenzFirstObjekt ?? '')
      }
    }, [dcfParzelle]);
  
  return (
    <AutoSavingFormWrapper
      className={""}
      onSubmit={onSubmit}
      apiErrors={apiErrors}
      defaultValues={dcfParzelle}
    >
      <PlasmicDcf011DetailseiteParzelle
        root={{ ref }}
        {...specificComponentAdjustments}
        {...props}
      />
    </AutoSavingFormWrapper>
  );
}

const Dcf011DetailseiteParzelle = React.forwardRef(Dcf011DetailseiteParzelle_);
export default withCurrentEWGParameters((props) => {
  const { objectId } = props;
  const { parzelle } = useParams();
  const { language } = useRegionContext();

  const composedComponent = React.useMemo(() => {
    return flow([
      withInitialResourceLoad([
        { apiCall: () => getDcfParzelleInput(objectId, parzelle!), dataStoreName: DataStoreName.dcfParzelle },
        { apiCall: () => getDcfParzelleMod(objectId, parzelle!, language), dataStoreName: DataStoreName.erfassungMod },
      ]),
      withAutosaveProvider({
        apiCall: (data) => saveDcfParzelleDraft(objectId, parzelle!, language, data as DcfParzelle),
        autosaveDataStore: DataStoreName.erfassungMod,
        userInputDataStore: DataStoreName.dcfParzelle
      }),
      withDataStoreProvider({})
    ])(Dcf011DetailseiteParzelle);
  }, [objectId, language]);

  return createElement(composedComponent, props);
});
