import * as React from "react";
import {
  PlasmicRemmsInfoCards,
  DefaultRemmsInfoCardsProps
} from "./plasmic/imbas_marktwert_seiten/PlasmicRemmsInfoCards";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { DynamicTextType, formatText } from "./custom/DynamicText";
import { t } from "i18next";
import { useEffect, useState } from "react";

export interface RemmsInfoCardsProps extends DefaultRemmsInfoCardsProps { }

function formatNumber(number: number | undefined): string | undefined {
  if (number)
    return formatText(t, DynamicTextType.Number, (number * 1000).toString());

  return '-';
}

function RemmsInfoCards_(
  props: RemmsInfoCardsProps,
  ref: HTMLElementRefOf<"div">
) {
  const [isVisible, setIsVisible] = useState<boolean>(true);

  useEffect(() => {
    if (props.hide) {
      setIsVisible(false);
    }
  }, [props.hide]);

  return (
    <>
      {isVisible &&
        <PlasmicRemmsInfoCards
          root={{ ref }}
          {...props}
          number={formatNumber(props.infoCardValue)}
        />}
    </>
  );
}

const RemmsInfoCards = React.forwardRef(RemmsInfoCards_);
export default RemmsInfoCards;
