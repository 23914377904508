import * as React from "react";
import {
  PlasmicProcessCardSection,
  DefaultProcessCardSectionProps
} from "./plasmic/imbas_23_fpre/PlasmicProcessCardSection";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { useState } from "react";

export interface ProcessCardSectionProps
  extends DefaultProcessCardSectionProps {}

function ProcessCardSection_(
  props: ProcessCardSectionProps,
  ref: HTMLElementRefOf<"div">
) {
const [isClosed, setIsClosed] = useState(props.isClosed);
  return (
    <PlasmicProcessCardSection 
      root={{ ref }} 
      {...props} 
      expandButton={{
        onClick: () => setIsClosed(!isClosed)
      }}
      closeButton={{
        onClick: () => setIsClosed(!isClosed)
      }}
      isClosed= {isClosed}
    />
  );
}

const ProcessCardSection = React.forwardRef(ProcessCardSection_);
export default ProcessCardSection;
