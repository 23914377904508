import * as React from "react";
import {
  PlasmicComparablesCell,
  DefaultComparablesCellProps
} from "./plasmic/imbas_23_fpre/PlasmicComparablesCell";
import { HTMLElementRefOf } from "@plasmicapp/react-web";

export interface ComparablesCellProps extends DefaultComparablesCellProps {}

function ComparablesCell_(
  props: ComparablesCellProps,
  ref: HTMLElementRefOf<"div">
) {
  return <PlasmicComparablesCell root={{ ref }} {...props} />;
}

const ComparablesCell = React.forwardRef(ComparablesCell_);
export default ComparablesCell;
