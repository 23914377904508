import * as React from "react";
import {
  PlasmicComparablesAvarage,
  DefaultComparablesAvarageProps
} from "./plasmic/imbas_marktwert_seiten/PlasmicComparablesAvarage";
import { HTMLElementRefOf } from "@plasmicapp/react-web";

export interface ComparablesAvarageProps
  extends DefaultComparablesAvarageProps {}

function ComparablesAvarage_(
  props: ComparablesAvarageProps,
  ref: HTMLElementRefOf<"div">
) {
  return (
    <PlasmicComparablesAvarage 
      root={{ ref }} 
      {...props} 
    />);
}

const ComparablesAvarage = React.forwardRef(ComparablesAvarage_);
export default ComparablesAvarage;
