// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type UmganaIconSvgIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function UmganaIconSvgIcon(props: UmganaIconSvgIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      xmlSpace={"preserve"}
      overflow={"hidden"}
      viewBox={"0 0 96 96"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <g fill={"currentColor"}>
        <path
          d={
            "M68 30.2c-2.4 0-4.4-2-4.3-4.5 0-2.4 2-4.4 4.4-4.4 2.4 0 4.4 2 4.4 4.4 0 1.2-.5 2.3-1.3 3.1-.9.9-2 1.4-3.2 1.4zm-4.3-13.9c-2.2.8-3.9 2.5-5 4.5-1.4 2.7-1.6 5.8-.5 8.6L63 39.8l3.2 6.6c.3.7 1 1.1 1.8 1.1s1.5-.4 1.8-1.1l3.2-6.6 4.8-10.3c.5-1.2.7-2.5.7-3.8 0-7-7.2-12.3-14.8-9.4z"
          }
        ></path>

        <path
          d={
            "M46 72.8l-16-8V32.2l16 8v32.6zm-20-8l-12 6V38.2l12-6v32.6zm55.4-33.6l-2.5 5.4 3.1 1.6v32.6l-12-6V51.5h-4v13.3l-16 8V40.2l7.1-3.6-2.5-5.4-6.6 3.3-20-10-20 10v46l20-10 20 10 20-10 20 10v-46l-6.6-3.3z"
          }
        ></path>
      </g>
    </svg>
  );
}

export default UmganaIconSvgIcon;
/* prettier-ignore-end */
