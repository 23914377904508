import * as React from "react";
import {
  PlasmicRemmsTableRow,
  DefaultRemmsTableRowProps
} from "./plasmic/imbas_marktwert_seiten/PlasmicRemmsTableRow";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { useDynamicDropdownValue } from "../context/DynamicDropdownValuesContext";
import { t } from "i18next";
import { getErfassungPathByNUTZ } from "../utils";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useObjectInfoContext } from "../context/ObjectInfoContext";

export interface RemmsTableRowProps extends DefaultRemmsTableRowProps { }

function RemmsTableRow_(
  props: RemmsTableRowProps,
  ref: HTMLElementRefOf<"div">
) {
  const { objectInfo } = useObjectInfoContext();
  const NUTZ = objectInfo?.NUTZ;
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState<boolean>(true);
  let displayName = props.value;
  let displayNameAddonText = null;
  const dynamicDropdownValueItem = useDynamicDropdownValue(props.name, Number(props.value));
  if (dynamicDropdownValueItem) {
    displayName = t(dynamicDropdownValueItem.Name, { ns: "plasmic" });
  }

  const dynamicDropdownValueItemAddonText = useDynamicDropdownValue(props.addonName, Number(props.addonText));
  if (dynamicDropdownValueItemAddonText) {
    displayNameAddonText = t(dynamicDropdownValueItemAddonText.Name, { ns: "plasmic" });
  }

  useEffect(() => {
    if (props.hide) {
      setIsVisible(false);
      return;
    }
    if (!props.hideIfEmpty)
      return;
    setIsVisible(props.inputstate === 1);
  });

  return (
    <>
      {isVisible &&
        <PlasmicRemmsTableRow
          root={{ ref }}
          {...props}
          value={displayName}
          addonText={displayNameAddonText ?? props.addonText}
          state={getInputState(props.inputstate)}
          adjustButton={{
            onClick: () => {
              const cardId = props.cardId;
              const objectId = props.objectId;
              navigate(`${getErfassungPathByNUTZ(NUTZ!, { objectId })}#${cardId}`, { state: { cardId, open: true } });
            }
          }}
        />}
    </>
  );
}

function getInputState(state: number | undefined | null): 'declared' | 'undeclared' | 'calculated' {
  switch (state) {
    case 0:
      return 'undeclared';
    case 1:
      return 'declared';
    case 2:
      return 'calculated';
    default:
      return 'undeclared';
  }
}

const RemmsTableRow = React.forwardRef(RemmsTableRow_);
export default RemmsTableRow;
