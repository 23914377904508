import * as React from "react";
import {
  PlasmicDcfPlotRow,
  DefaultDcfPlotRowProps
} from "./plasmic/imbas_dcf_seiten/PlasmicDcfPlotRow";
import { HTMLElementRefOf } from "@plasmicapp/react-web";

export interface DcfPlotRowProps extends DefaultDcfPlotRowProps {
  index: number;
}

function DcfPlotRow_(
  { index, ...props }: DcfPlotRowProps,
   ref: HTMLElementRefOf<"div">
) {

  return (
    <PlasmicDcfPlotRow 
      root={{ ref }} 
      {...props}
      index={index + 1}
      referenz={{
        name: `Parzellen.[${index}].Referenz`
      }}
      egrid={{
        name: `Parzellen.[${index}].EGRID`
      }}
      land={{
        name: `Parzellen.[${index}].LAND`
      }}
      oberirdischeGf={{
        name: `Parzellen.[${index}].OberirdischeGF`
      }}
      deleteButton={{ onClick: props.onDelete }}
    />
  );
}

const DcfPlotRow = React.forwardRef(DcfPlotRow_);
export default DcfPlotRow;
