import React, { createElement, useCallback, useEffect, useState } from "react";
import {
  PlasmicDcf010Grundlagen,
  DefaultDcf010GrundlagenProps,
  PlasmicDcf010Grundlagen__OverridesType
} from "./plasmic/imbas_dcf_seiten/PlasmicDcf010Grundlagen";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { FormWrapperProps } from "./custom/FormWrapper";
import { useRegionContext } from "../context/RegionContext";
import { toast } from "react-toastify";
import { t } from "i18next";
import { EWGParameters, withCurrentEWGParameters } from "../withCurrentEWGParameters";
import { flow } from "lodash";
import { DataStoreName, useDataStore, withDataStoreProvider } from "../store/DataStoreProvider";
import { AutoSavingFormWrapper } from "./custom/AutoSavingFormWrapper";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ApplicationRoutes } from "../ApplicationRoutes";
import { isErrorResponse, processErrorResponse } from "../api/apiClient";
import { DcfInput, DcfObjekt, getDcfInput, getDcfMod, saveDcfInput, saveDcfInputDraft } from "../api/endpoints/dcf";
import { withAutosaveProvider } from "../store/AutosaveProvider";
import withInitialResourceLoad from "../store/withInitialResourceLoad";
import { DcfParzelle } from "./DcfPlotGroup";
import { DcfMassnahme } from "./DcfMeasureGroup";
import { RENA } from "./DcfMeasureRow";

type ApiErrors = FormWrapperProps<DcfInput>["apiErrors"];
type MultiSelectItem = { label: string | null | undefined; value: string | null | undefined };

export interface Dcf010GrundlagenProps extends DefaultDcf010GrundlagenProps, EWGParameters {}

function Dcf010Grundlagen_(
  { objectId, ...props }: Dcf010GrundlagenProps,
  ref: HTMLElementRefOf<"div">
) {

  const { location } = useRegionContext();
  const { dataStore: { dcfInput } } = useDataStore();
  const [apiErrors, setApiErrors] = React.useState<ApiErrors>();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(Boolean(false));
  const { i18n } = useTranslation();
  const [enteredObjects, setEnteredObjects] = useState<MultiSelectItem[]>([]);
  const [selectedEgrids, setSelectedEgrids] = useState<string[]>([]);
  const [dcfObjekte, setDcfObjekte] = useState<DcfObjekt[]>([]);
  const [plotDetailButtonText, setPlotDetailButtonText] = useState<string>(t("Details") ?? "Details");

  if(location?.WGS84Lat == null || location?.WGS84Lng == null)
  {
    toast.error(t("toast.missing_coordinates"), { toastId: "validation_error" });
  }
  
  const onSubmit = useCallback(async (data: DcfInput) => {
    try {
      setIsSubmitting(true);
      await saveDcfInput(objectId, data);
      const parzelle = data.Parzellen![0].Referenz ?? '';
      navigate(ApplicationRoutes.getPath("dcf011DetailsParzelle", { objectId, parzelle }));
    } catch (err) {
      setIsSubmitting(false);
      if (isErrorResponse(err)) {
        setApiErrors(processErrorResponse(err.response.data, i18n.language));
        toast.error(t("toast.validation_error"), { toastId: "validation_error" });
      }
    }
  }, [navigate, objectId]);

  const specificComponentAdjustments: PlasmicDcf010Grundlagen__OverridesType = {
    submitButton: {
      isLoading: isSubmitting,
    }
  };

  useEffect(() => {
    if (dcfInput) {
      const details = t("Details") ?? "Details";
      if (dcfInput.Objekte) {
        const newEnteredObjects: MultiSelectItem[] = dcfInput?.Objekte.map(item => ({
          label: item.Referenz,
          value: item.Referenz
        }));
        setEnteredObjects([...newEnteredObjects]);  
        setDcfObjekte([...dcfInput.Objekte])
      }
      if (dcfInput.Parzellen && dcfInput.Parzellen.length > 0) {
        const egrids = dcfInput.Parzellen
          .filter(p => p.EGRID)
          .map(p => p.EGRID as string);

        setSelectedEgrids([...egrids])
        setPlotDetailButtonText(`${details} ${dcfInput.Parzellen[0].Referenz}`)
        return;
      }
      setPlotDetailButtonText(details);
    }
  }, [dcfInput]);

  return (
    <AutoSavingFormWrapper
      className={""}
      onSubmit={onSubmit}
      apiErrors={apiErrors}
      defaultValues={dcfInput}
    >
      <PlasmicDcf010Grundlagen
        root={{ ref }}
        {...specificComponentAdjustments}
        {...props}
        plots={{
          plotFactory: (referenz: string) => {
            const newPlot: DcfParzelle = {
              Referenz: referenz,
              EGRID: null,
              LAND: null,
              OberirdischeGF: null
            };
            return newPlot;
          }
        }}
        objects={{
          egrids: selectedEgrids,
          objekte: dcfObjekte,
          objectFactory: (referenz: string, egid: number | null, adresse: string | null, baujahr: number | null) => {
            const newObject: DcfObjekt = {
              Referenz: referenz,
              EGID: egid,
              Adresse: adresse,
              Baujahr: baujahr,
              IsEWG: false,
              NUTZs: undefined              
            };
            setDcfObjekte(prev => [...prev, newObject]);

            return newObject;
          }
        }}
        measures={{
          objects: enteredObjects,
          measureFactory: (value: number) => {
            const newMeasure: DcfMassnahme = {
              RENA: value as RENA,
              Umfang: null,
              Zeitraum: null,
              Investitionsbetrag: null,
              Ertragsausfall: null
            }
            return newMeasure;
          }
        }}
        submitButton={{
          text: plotDetailButtonText
        }}
      />
    </AutoSavingFormWrapper>
  );
}

const Dcf010Grundlagen = React.forwardRef(Dcf010Grundlagen_);

export default withCurrentEWGParameters((props) => {
  const { objectId } = props;
  const { language } = useRegionContext();

  const composedComponent = React.useMemo(() => {
    return flow([
      withInitialResourceLoad([
        { apiCall: () => getDcfInput(objectId), dataStoreName: DataStoreName.dcfInput },
        { apiCall: () => getDcfMod(objectId, language), dataStoreName: DataStoreName.erfassungMod },
      ]),
      withAutosaveProvider({
        apiCall: (data) => saveDcfInputDraft(objectId, language, data as DcfInput),
        autosaveDataStore: DataStoreName.erfassungMod,
        userInputDataStore: DataStoreName.dcfInput
      }),
      withDataStoreProvider({})
    ])(Dcf010Grundlagen);
  }, [objectId, language]);

  return createElement(composedComponent, props);
});
