import * as React from "react";
import { Select } from 'antd';
import {
  DefaultMultiSelectProps
} from "./plasmic/imbas_23_fpre/PlasmicMultiSelect";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { useFormContext } from "react-hook-form";

export interface MultiSelectProps extends DefaultMultiSelectProps {
  name: string;
  value: string[];
}

function MultiSelect_(
  { value, ...props }: MultiSelectProps,
  ref: HTMLElementRefOf<"div">
) {
  const { setValue } = useFormContext(); 
  const handleChange = (value: string[]) => { setValue(props.name, value) };

  return (
    <Select 
      {...props}
      mode="multiple"
      onChange={handleChange}
      options={props.options}
      defaultValue={value}
    />
  );
}

 
const MultiSelect = React.forwardRef(MultiSelect_);
export default MultiSelect;
