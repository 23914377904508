import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DefaultAddressSearchBarProps, PlasmicAddressSearchBar } from "./plasmic/imbas_23_fpre/PlasmicAddressSearchBar";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { useAddressPrediction } from "../hooks/useAddressPrediction";
import { useRegionContext } from "../context/RegionContext";
import { AutocompleteResultDTO } from "./AutocompleteTextInput";
import { parseLocationString } from "../utils";
import { AddressPrediction, getLocationDetail } from "../api/endpoints/location";
import { isErrorResponse } from "../api/apiClient";
import { toast } from "react-toastify";
import { putLocation } from "../api/endpoints/objectInfo";
import { useNavigate, useParams } from "react-router-dom";
import { ApplicationRoutes } from "../ApplicationRoutes";

export interface AddressSearchBarProps extends DefaultAddressSearchBarProps {
}

function AddressSearchBar_(
  props: AddressSearchBarProps,
  ref: HTMLElementRefOf<"div">
) {
  const navigate = useNavigate();
  const { location, setLocation, language, country } = useRegionContext();
  const { search, addressPredictions } = useAddressPrediction();
  const [searchInputValue, setSearchInputValue] = useState<string>("");
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { objectId = undefined } = useParams();

  useEffect(() => {
    setIsActive(!!location);
    setIsInvalid(false);
  }, [location]);

  useEffect(() => {
    search(searchInputValue);
  }, [search, searchInputValue]);

  const handleSearch = useCallback((value: string | null) => {
    setSearchInputValue(value ?? "");
  }, []);

  const handleDropdownSelection = useCallback(async (selectedAddress: AddressPrediction) => {
    const placeId = selectedAddress.PlacesID;
    setIsInvalid(!placeId);
    if (!placeId) return;
    try {
      getLocationDetail(placeId, language)
        .then(async ({ data }) => {
          if (objectId) {
            setIsLoading(true);
            await putLocation(objectId, language, data);
            navigate(0);
          }
          else {
            setLocation(data);
          }
        })
        .catch((_err) => setIsInvalid(true))
    } catch (err) {
      setIsLoading(false);
      if (isErrorResponse(err)) {
        toast.error(err.message, { toastId: "validation_error" });
      }
    } finally {
      setIsLoading(false);
    }
  }, [setLocation, country]);

  const handleReset = useCallback(() => {
    setLocation(undefined);
    setSearchInputValue("");
  }, [setLocation]);

  const autocompleteResults: Array<AutocompleteResultDTO> = useMemo(() => (
    addressPredictions.map((addressPrediction) => ({
      label: addressPrediction.Address ?? "",
      onClick: () => handleDropdownSelection(addressPrediction),
      isRegion: !addressPrediction.FpreIsAccurate,
    }))
  ), [addressPredictions, handleDropdownSelection]);

  return (
    <PlasmicAddressSearchBar
      root={{ ref }}
      {...props}
      searchInput={{
        value: searchInputValue,
        onChange: handleSearch,
        results: autocompleteResults,
      }}
      address={parseLocationString(location)}
      resetButton={{ onClick: handleReset }}
      isActive={isActive}
      addressIsInvalid={isInvalid}
      mapButton={{
        onClick: () => navigate(ApplicationRoutes.getPath("adressSelector", { objectId: objectId ?? "" }))
      }}
      mapButtonIsActive={{
        onClick: () => navigate(ApplicationRoutes.getPath("adressSelector", { objectId: objectId ?? "" }))
      }}
      isLoading={isLoading}
    />
  );
}

const AddressSearchBar = React.forwardRef(AddressSearchBar_);
export default AddressSearchBar;
