import React, { createContext, Dispatch, SetStateAction, useEffect, useLayoutEffect, useState } from "react";
import { DefaultScrollNavProps, PlasmicScrollNav } from "./plasmic/imbas_23_fpre/PlasmicScrollNav";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { scrollElementIntoView } from "../utils";
import { useProcessHeaderContext } from "./ProcessHeader";

export const ScrollNavContext = createContext<{
  activeButton: string,
  setActiveButton: Dispatch<SetStateAction<string>>
}>({
  activeButton: "",
  setActiveButton: () => null
});

export interface ScrollNavProps extends DefaultScrollNavProps {
}

function ScrollNav_(
  { isHorizontal, ...props }: ScrollNavProps, ref: HTMLElementRefOf<"div">) {
  const [activeButton, setActiveButton] = useState<string>(decodeURIComponent(window.location.hash.substring(1)));
  const { showProcessHeader } = useProcessHeaderContext();
  const [top, setTop] = useState<number>();

  useLayoutEffect(() => {
    if (activeButton) {
      scrollElementIntoView(activeButton, isHorizontal as boolean ?? false);
    }
  }, [activeButton]);

  useEffect(() => {
    setTop(showProcessHeader ? 151 : 0)
  }, [showProcessHeader]);

  return (
    isHorizontal ?
      
    (<ScrollNavContext.Provider value={{ activeButton, setActiveButton }}>
    <PlasmicScrollNav root={{ ref }} {...props} />
  </ScrollNavContext.Provider>)
  :(
      <div
        ref={ref}
        style={{ 
          zIndex: 1,
          top: top ,
          position: "sticky",
        }}
      >
        <ScrollNavContext.Provider value={{ activeButton, setActiveButton }}>
          <PlasmicScrollNav root={{ ref }} {...props} />
        </ScrollNavContext.Provider>
      </div>)
  );
}

const ScrollNav = React.forwardRef(ScrollNav_);
export default ScrollNav;
