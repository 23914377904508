import * as React from "react";
import moment from "moment";
import {
  PlasmicComparables,
  DefaultComparablesProps
} from "./plasmic/imbas_marktwert_seiten/PlasmicComparables";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { useComparablesContext } from "../context/ComparablesContext";
import ComparablesRow from "./ComparablesRow";
import { useDataStore } from "../store/DataStoreProvider";
import { useObjectInfoContext } from "../context/ObjectInfoContext";
import { useDynamicDropdownValue } from "../context/DynamicDropdownValuesContext";
import { components } from "../api/types";
import { t } from "i18next";

export interface ComparablesProps extends DefaultComparablesProps {}


function Comparables_(props: ComparablesProps, ref: HTMLElementRefOf<"div">) 
{
  const { isloading, comparablesData } = useComparablesContext();
  const { dataStore: { marktwertCalc } } = useDataStore();
  const { objectInfo } = useObjectInfoContext();
  const TranslateComparableType  = (type: components["schemas"]["ComparableType"] | undefined) => 
    t(useDynamicDropdownValue("ComparableType", type ?? 0)?.Name ?? "", { ns: "plasmic" });
 
  return (
    <PlasmicComparables
      root={{ ref }} 
      {...props} 
      state={isloading && comparablesData === undefined ? "isLoading" : comparablesData ? "loaded": "unavailable" }
    
      hidePreiSm2={comparablesData?.HidePREISm2}
      hideDistanz={comparablesData?.HideDISTANZ}
      hideStrlieg={comparablesData?.HideSTRLIEG}
      hidePlzlieg={comparablesData?.HidePLZLIEG}
      hideOrtlieg={comparablesData?.HideORTLIEG}
      hideTyp={comparablesData?.HideTYP}
      hideNf={comparablesData?.HideNF}
      hideVol={comparablesData?.HideVOL}
      hideLand={comparablesData?.HideLAND}
      hideZust={comparablesData?.HideZUST}
      hideStand={comparablesData?.HideSTAND}
      hideEtage={comparablesData?.HideETAGE}
      hideId={comparablesData?.HideID}
      currentRating={{
        preis: marktwertCalc?.MW_G ?? undefined,
        preiSm2:  marktwertCalc?.MW_G_ChfM2Wohnfläche ?? undefined,
        strlieg: objectInfo?.Location?.Street + " " + objectInfo?.Location?.Number,
        plzlieg: objectInfo?.Location?.ZipCode?? undefined,
        ortlieg: objectInfo?.Location?.City?? undefined,
        datum: moment().format('yyyy/MM'),
        typ: undefined,
        nf: comparablesData?.CurrentRating?.NF ?? undefined,
        vol: comparablesData?.CurrentRating?.VOL ?? undefined,
        ziz: comparablesData?.CurrentRating?.ZIZ ?? undefined,
        bauj: comparablesData?.CurrentRating?.BAUJ ?? undefined,
        land: comparablesData?.CurrentRating?.LAND ?? undefined,
        zust: comparablesData?.CurrentRating?.ZUST ?? undefined,
        mikro: comparablesData?.CurrentRating?.MIKRO ?? undefined,
        stand: comparablesData?.CurrentRating?.STAND ?? undefined,
        etage: comparablesData?.CurrentRating?.ETAGE ?? undefined,
        id: undefined
      }}
      comparablesAvarage={{
        top20Avarage: comparablesData?.NumberOfResults === 20,
        preisTop10Mittelwert: comparablesData?.ComparablesOutput?.Average?.PREIS_TOP10_MITTELWERT ?? undefined,
        preisTop20Mittelwert: comparablesData?.ComparablesOutput?.Average?.PREIS_TOP20_MITTELWERT ?? undefined,
        preiSm2Top10Mittelwert: comparablesData?.ComparablesOutput?.Average?.PREISm2_TOP10_MITTELWERT ?? undefined,
        preiSm2Top20Mittelwert: comparablesData?.ComparablesOutput?.Average?.PREISm2_TOP20_MITTELWERT ?? undefined,
        nfTop10Mittelwert: comparablesData?.ComparablesOutput?.Average?.NF_TOP10_MITTELWERT ?? undefined,
        nfTop20Mittelwert: comparablesData?.ComparablesOutput?.Average?.NF_TOP20_MITTELWERT ?? undefined,
        volTop10Mittelwert: comparablesData?.ComparablesOutput?.Average?.VOL_TOP10_MITTELWERT ?? undefined,
        volTop20Mittelwert: comparablesData?.ComparablesOutput?.Average?.VOL_TOP20_MITTELWERT ?? undefined,
        zizTop10Mittelwert: comparablesData?.ComparablesOutput?.Average?.ZIZ_TOP10_MITTELWERT ?? undefined,
        zizTop20Mittelwert: comparablesData?.ComparablesOutput?.Average?.ZIZ_TOP20_MITTELWERT ?? undefined,
        baujTop10Mittelwert: comparablesData?.ComparablesOutput?.Average?.BAUJ_TOP10_MITTELWERT ?? undefined,
        baujTop20Mittelwert: comparablesData?.ComparablesOutput?.Average?.BAUJ_TOP20_MITTELWERT ?? undefined,
        mikroTop10Mittelwert: comparablesData?.ComparablesOutput?.Average?.MIKRO_TOP10_MITTELWERT ?? undefined,
        mikroTop20Mittelwert: comparablesData?.ComparablesOutput?.Average?.MIKRO_TOP20_MITTELWERT ?? undefined,
        standTop10Mittelwert: comparablesData?.ComparablesOutput?.Average?.STAND_TOP10_MITTELWERT ?? undefined,
        standTop20Mittelwert: comparablesData?.ComparablesOutput?.Average?.STAND_TOP20_MITTELWERT ?? undefined,
        zustTop10Mittelwert: comparablesData?.ComparablesOutput?.Average?.ZUST_TOP10_MITTELWERT ?? undefined,
        zustTop20Mittelwert: comparablesData?.ComparablesOutput?.Average?.ZUST_TOP20_MITTELWERT ?? undefined,
        etageTop10Mittelwert: comparablesData?.ComparablesOutput?.Average?.ETAGE_TOP10_MITTELWERT ?? undefined,
        etageTop20Mittelwert: comparablesData?.ComparablesOutput?.Average?.PREISm2_TOP20_MITTELWERT ?? undefined,
      }}
      comparables={{
        children: comparablesData?.ComparablesOutput?.Comparables?.map((comparable, index) =>
        {
          return (
            <ComparablesRow
              key={index}      
              hidePreiSm2={comparablesData?.HidePREISm2}
              hideDistanz={comparablesData?.HideDISTANZ}
              hideStrlieg={comparablesData?.HideSTRLIEG}
              hidePlzlieg={comparablesData?.HidePLZLIEG}
              hideOrtlieg={comparablesData?.HideORTLIEG}
              hideTyp={comparablesData?.HideTYP}
              hideNf={comparablesData?.HideNF}
              hideVol={comparablesData?.HideVOL}
              hideLand={comparablesData?.HideLAND}
              hideZust={comparablesData?.HideZUST}
              hideStand={comparablesData?.HideSTAND}
              hideEtage={comparablesData?.HideETAGE}
              hideId={comparablesData?.HideID}
              rang={comparable.RANK?? undefined}
              vergleich={comparable.VERGLEICH ?? undefined}
              preis={comparable.PREIS ?? undefined}
              preiSm2={comparable.PREISm2 ?? undefined}
              distanz={comparable.DISTANZ ?? undefined}
              strlieg={comparable.STRLIEG ?? undefined}
              plzlieg={comparable.PLZLIEG ?? undefined}
              ortlieg={comparable.ORTLIEG ?? undefined}
              datum={comparable.DATUM ?? undefined}
              typ={ TranslateComparableType(comparable.TYP) ?? undefined}
              nf={comparable.NF ?? undefined}
              vol={comparable.VOL ?? undefined}
              land={comparable.LAND ?? undefined}
              ziz={comparable.ZIZ ?? undefined}
              bauj={comparable.BAUJ ?? undefined}
              zust={comparable.ZUST ?? undefined}
              mikro={comparable.MIKRO ?? undefined}
              stand={comparable.STAND ?? undefined}
              etage={comparable.ETAGE?.toString() ?? undefined}
              id={comparable.ID?.toString() ?? undefined} 
              
            />
          )
        })
      }}
    />
  );
}

const Comparables = React.forwardRef(Comparables_);
export default Comparables;
