import * as React from "react";
import {
  PlasmicDcf012DetailseiteObjekt,
  DefaultDcf012DetailseiteObjektProps,
  PlasmicDcf012DetailseiteObjekt__OverridesType
} from "./plasmic/imbas_dcf_seiten/PlasmicDcf012DetailseiteObjekt";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { 
  DcfObjekt, 
  getDcfObjektInput, 
  getDcfObjektMod, 
  saveDcfObjektDraft, 
  saveDcfObjektInput } from "../api/endpoints/dcf";
import { FormWrapperProps } from "./custom/FormWrapper";
import { EWGParameters, withCurrentEWGParameters } from "../withCurrentEWGParameters";
import { toast } from "react-toastify";
import { useRegionContext } from "../context/RegionContext";
import { DataStoreName, useDataStore, withDataStoreProvider } from "../store/DataStoreProvider";
import { useNavigate, useParams } from "react-router";
import { isErrorResponse, processErrorResponse } from "../api/apiClient";
import { createElement, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { AutoSavingFormWrapper } from "./custom/AutoSavingFormWrapper";
import { flow } from "lodash";
import withInitialResourceLoad from "../store/withInitialResourceLoad";
import { withAutosaveProvider } from "../store/AutosaveProvider";

type ApiErrors = FormWrapperProps<DcfObjekt>["apiErrors"];
export interface Dcf012DetailseiteObjektProps
  extends DefaultDcf012DetailseiteObjektProps, EWGParameters {}

function Dcf012DetailseiteObjekt_(
  { objectId, ...props }: Dcf012DetailseiteObjektProps,
  ref: HTMLElementRefOf<"div">
) {

  const { location } = useRegionContext();
  const { dataStore: { dcfObjekt } } = useDataStore();
  const [apiErrors, setApiErrors] = React.useState<ApiErrors>();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(Boolean(false));
  const { i18n } = useTranslation();

  if(location?.WGS84Lat == null || location?.WGS84Lng == null)
  {
    toast.error(t("toast.missing_coordinates"), { toastId: "validation_error" });
  }
 const onSubmit = useCallback(async (data: DcfObjekt) => {
    try {
      saveDcfObjektInput(objectId, data.Referenz!, data);
    } catch (err) {
      setIsSubmitting(false);
      if (isErrorResponse(err)) {
        setApiErrors(processErrorResponse(err.response.data, i18n.language));
        toast.error(t("toast.validation_error"), { toastId: "validation_error" });
      }
    }
  }, [navigate, objectId]);

  const specificComponentAdjustments: PlasmicDcf012DetailseiteObjekt__OverridesType = {
      submitButton: {
        isLoading: isSubmitting,
      }
  };

  return (
    <AutoSavingFormWrapper
      className={""}
      onSubmit={onSubmit}
      apiErrors={apiErrors}
      defaultValues={dcfObjekt}
    >
      <PlasmicDcf012DetailseiteObjekt 
        root={{ ref }} 
        {...specificComponentAdjustments}
        {...props} 
      />
    </AutoSavingFormWrapper>
  );
}

const Dcf012DetailseiteObjekt = React.forwardRef(Dcf012DetailseiteObjekt_);
export default withCurrentEWGParameters((props) => {
  const { objectId } = props;
  const { objekt } = useParams();
  const { language } = useRegionContext();

  const composedComponent = React.useMemo(() => {
    return flow([
      withInitialResourceLoad([
        { apiCall: () => getDcfObjektInput(objectId, objekt!), dataStoreName: DataStoreName.dcfObjekt },
        { apiCall: () => getDcfObjektMod(objectId, objekt!, language), dataStoreName: DataStoreName.erfassungMod },
      ]),
      withAutosaveProvider({
        apiCall: (data) => saveDcfObjektDraft(objectId, objekt!, language, data as DcfObjekt),
        autosaveDataStore: DataStoreName.erfassungMod,
        userInputDataStore: DataStoreName.dcfObjekt
      }),
      withDataStoreProvider({})
    ])(Dcf012DetailseiteObjekt);
  }, [objectId, language]);

  return createElement(composedComponent, props);
});
