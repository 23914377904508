import { components } from "../types";
import apiClient from "../apiClient";
import { ErfassungMod } from "./ewg";
import { Language } from "../../context/RegionContext";
import qs from "qs";
import { DcfParzelle } from "../../components/DcfPlotGroup";

export type DcfInput = components["schemas"]["ImbasDcfInput"];
export type DcfObjekt = components["schemas"]["ImbasDcfObjekt"];

const BASE_PATH = (objectId: string) => `/Dcf/${objectId}`;

export const getDcfInput = async (objectId: string) => {
  return await apiClient.get<DcfInput>(`${BASE_PATH(objectId)}/DcfInput`);
};
export const getDcfParzelleInput = async (objectId: string, parzelle: string) => {
  return await apiClient.get<DcfInput>(`${BASE_PATH(objectId)}/DcfParzelle/${parzelle}`);
};
export const getDcfObjektInput = async (objectId: string, objekt: string) => {
  return await apiClient.get<DcfInput>(`${BASE_PATH(objectId)}/DcfObjekt/${objekt}`);
};

export const getDcfMod = async (objectId: string, sprache: Language) => {
  return await apiClient.get<ErfassungMod>(`${BASE_PATH(objectId)}/DcfMod`, { params: { sprache } });
};
export const getDcfParzelleMod = async (objectId: string, parzelle: string, sprache: Language) => {
  return await apiClient.get<ErfassungMod>(
    `${BASE_PATH(objectId)}/DcfParzelleMod/${parzelle}`, 
    { params: { sprache } }
  );
};
export const getDcfObjektMod = async (objectId: string, objekt: string, sprache: Language) => {
  return await apiClient.get<ErfassungMod>(
    `${BASE_PATH(objectId)}/DcfObjektMod/${objekt}`, 
    { params: { sprache } 
  });
};

export const saveDcfInputDraft = async (objectId: string, sprache: Language, body: DcfInput) => {
  return await apiClient.put<void>(`${BASE_PATH(objectId)}/DcfInputDraft`, body, { params: { sprache } });
};
export const saveDcfParzelleDraft = 
  async (objectId: string, parzelle: string, sprache: Language, body: DcfParzelle) => {
    return await apiClient.post<void>(
      `${BASE_PATH(objectId)}/DcfParzelleDraft/${parzelle}/`, 
      body,
    { params: { sprache } });
  };

export const saveDcfObjektDraft = async (objectId: string, objekt: string, sprache: Language, body: DcfObjekt) => {
  return await apiClient.post<void>(
    `${BASE_PATH(objectId)}/DcfObjektDraft/${objekt}/`, 
    body,
   { params: { sprache } });
};

export const saveDcfInput = async (objectId: string, body: DcfInput) => {
  return await apiClient.put<void>(`${BASE_PATH(objectId)}/DcfInput`, body);
};
export const saveDcfParzelleInput = async (objectId: string, parzelle: string, body: DcfParzelle) => {
  return await apiClient.put<void>(`${BASE_PATH(objectId)}/DcfParzelleInput/${parzelle}`, body);
};
export const saveDcfObjektInput = async (objectId: string, objekt: string, body: DcfObjekt) => {
  return await apiClient.put<void>(`${BASE_PATH(objectId)}/DcfObjektInput/${objekt}`, body);
};

export const getGWRObjects = async (egrids: string[]) => {
  return await apiClient.get<DcfObjekt[]>(`dcf/Objects`, 
    {
      params: { egrids }, 
      paramsSerializer: params => { return qs.stringify(params) } 
    });
};
