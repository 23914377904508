import Dashboard010 from "./components/Dashboard010";
import Ewg010ErfassungFull from "./components/Ewg010ErfassungFull";
import ImbasLegacy from "./pages/ImbasLegacy";
import ImbasLegacyErfassung from "./pages/ImbasLegacyErfassung";
import PlasmicHost from "./pages/PlasmicHost";
import React from "react";
import { generatePath, ParamParseKey } from "react-router-dom";
import Ewg030Bericht from "./components/Ewg030Bericht";
import ErrorPage from "./components/ErrorPage";
import Efh010ErfassungFull from "./components/Efh010ErfassungFull";
import AdminPage from "./components/AdminPage";
import AddressSelectorPage from "./components/AddressSelectorPage";
import Ewgefh020Marktwert from "./components/Ewgefh020Marktwert";
import ApiDocsIframe from "./components/custom/ApiDocsIframe";
import Dcf010Grundlagen from "./components/Dcf010Grundlagen";
import Dcf011DetailseiteParzelle from "./components/Dcf011DetailseiteParzelle";
import Dcf012DetailseiteObjekt from "./components/Dcf012DetailseiteObjekt";
import Dcf020ErtragLeerstand from "./components/Dcf020ErtragLeerstand";
import DeEwgHerleitungNhk from "./components/DeEwgHerleitungNhk";

export type RouteConfig = {
  path: string,
  component: React.ComponentType,
  params?: readonly string[],
  isPublic?: boolean
};

export type NamedRoute = keyof typeof ROUTE_MAPPING;
export type RouteParams = {
  [K in NamedRoute]: K extends NamedRoute
  ? { [key in ParamParseKey<typeof ROUTE_MAPPING[K]["path"]>]: string }
  : never;
};

// All routes in the application are defined here, together with a name to reference in the code (key of object),
// the path and the component to render. If the component has params (e.g. /:id), they are specified in the
// "params" array and currently purely used to check the presence of the required parameter when navigating
// to the route make sure the route is valid (and provide type helpers).
// The "as const" modifier is important here to make the keys available in the type definition. Removing it
// would cause typescript to understand it as dynamic value which could change during runtime and thus is not available
// in the type definition.
const ROUTE_MAPPING = {
  root: { path: "/", component: Dashboard010 },
  admin: { path: "/admin", component: AdminPage },
  apidocs: { path: "/apidocs", component: ApiDocsIframe },
  ewg010Erfassung: { path: "/:objectId/erfassung/ewg", component: Ewg010ErfassungFull, params: ["objectId"] as const },
  efh010Erfassung: { path: "/:objectId/erfassung/efh", component: Efh010ErfassungFull, params: ["objectId"] as const },
  ewg020Marktwert: { path: "/:objectId/marktwert", component: Ewgefh020Marktwert, params: ["objectId"] as const },
  ewg020MarktwertHerleitungNHK: 
  { path: "/:objectId/herleitungnhk/ewg", component: DeEwgHerleitungNhk, params: ["objectId"] as const },
  ewg030Bericht: { path: "/:objectId/bericht", component: Ewg030Bericht, params: ["objectId"] as const },
  adressSelector: { path: "/adresse/:objectId?", component: AddressSelectorPage, params: ["objectId"] as const },
  imbasLegacy: { path: "/imbas/:imbasPath", component: ImbasLegacy, params: ["imbasPath"] as const },
  imbasLegacyErfassung: {
    path: "/imbas/erfassung/:imbasPath",
    component: ImbasLegacyErfassung, params: ["imbasPath"] as const
  },
  dcf010Grundlagen: { path: '/:objectId/dcf/grundlagen', component: Dcf010Grundlagen, params: ["objectId"] as const },
  dcf011DetailsParzelle: { 
    path: '/:objectId/dcf/details/parzelle/:parzelle', 
    component: Dcf011DetailseiteParzelle, 
    params: ["objectId", "parzelle"] as const,
  },
  dcf012DetailsObjekt: { 
    path: '/:objectId/dcf/details/objekt/:objekt', 
    component: Dcf012DetailseiteObjekt, 
    params: ["objectId", "objekt"] as const,
  },
  dcf020ErtragLeerstand: { 
    path: '/:objectId/dcf/ertragleerstand', 
    component: Dcf020ErtragLeerstand, 
    params: ["objectId", "objekt"] as const,
  },
  plasmicHost: { path: "/plasmic-host", component: PlasmicHost, isPublic: true },
  error: { path: "/error", component: ErrorPage },
} as const;

export const ApplicationRoutes = {
  getPath<T extends NamedRoute>(name: T, params?: RouteParams[T]): string {
    return generatePath<typeof ROUTE_MAPPING[T]["path"]>(ROUTE_MAPPING[name].path, params);
  },

  map<U>(func: (obj: RouteConfig) => U): U[] {
    return Object.values(ROUTE_MAPPING).map<U>(value => func(value));
  },
};
