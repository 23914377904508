import { HTMLElementRefOf } from "@plasmicapp/react-web";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApplicationRoutes } from "../ApplicationRoutes";
import { GemcInput, StaoInput, getGemc, getStao } from "../api/endpoints/download";
import { createObject } from "../api/endpoints/objectInfo";
import { components } from "../api/types";
import { useIdTokenClaims } from "../auth/authUtils";
import { useImbasLegacyContext } from "../context/ImbasLegacyContext";
import { Country, useRegionContext, deriveIsoLocale } from "../context/RegionContext";
import {
  downloadBlob,
  getErfassungPathByNUTZ,
  getFileNameFromContentDisposition,
  getLinkMetaanalyseKonjunktur,
  getLinkMetaanalyseImmobilien
} from "../utils";
import { DefaultDashboard010Props, PlasmicDashboard010 } from "./plasmic/imbas_23_fpre/PlasmicDashboard010";
import { isErrorResponse } from "../api/apiClient";
import { toast } from "react-toastify";
import { t } from "i18next";
import { useDebugContext } from "../context/DebugContext";

export interface Dashboard010Props extends DefaultDashboard010Props {
}

function Dashboard010_(
  props: Dashboard010Props,
  ref: HTMLElementRefOf<"div">
) {
  const navigate = useNavigate();
  const { language, country, location } = useRegionContext();
  const { setIsNewLegacyObject } = useImbasLegacyContext();
  const { isDebugMode, setIsDebugMode } = useDebugContext();

  const initializeObject = async (NUTZ: components["schemas"]["NUTZ"]): Promise<void> => {
    try {
      const objectInfo: components["schemas"]["ObjectInfo"] =
      {
        NUTZ: NUTZ,
        Location: location
      };
      await createObject(language, objectInfo).then(({ data }) => {
        navigate(getErfassungPathByNUTZ(NUTZ, { objectId: data.ObjectId! }));
      });
    } catch (err) {
      if (isErrorResponse(err)) {
        toast.error(t("toast.validation_error"), { toastId: "validation_error" });
      }
    }
  };

  const fpre_userData = useIdTokenClaims()?.fpre_userData;
  const allowedModules = useIdTokenClaims()?.fpre_allowedModules?.ModulInfo || [];
  const isModuleLocked = (name: string) => (
    !allowedModules.some(({ ModulName, Land }) => ModulName === name && Country[Land] === country)
  );
  const allowedRoles = useIdTokenClaims()?.fpre_allowedRoles?.RoleInfo || [];
  const isDebugAdmin = allowedRoles.some(
    ({ RoleName, Land }) => 
        RoleName === "AdminStao"
  );

  const downloadGemc = async (gemcType: components["schemas"]["GemcType"],
    fileType: components["schemas"]["FileType"],
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>) => {

    try {
      setIsLoading(true);
      const gemcInput: GemcInput = {
        wgs84Lng: location?.WGS84Lng,
        wgs84Lat: location?.WGS84Lat,
        sprache: language,
        land: country,
        fileType: fileType,
        gemcType: gemcType
      };
      const { data, headers } = await getGemc(gemcInput);
      const fileName = getFileNameFromContentDisposition(headers["content-disposition"]);
      downloadBlob(data, fileName);
    } catch (err) {
      console.log(err);
    } finally {      
      setIsLoading(false);
    }
  };

  const downloadStao = async (staoProductCode: components["schemas"]["StaoProductCode"],
                        fileType: components["schemas"]["FileType"],
                        setIsLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
    try {
      setIsLoading(true);
      const staoInput: StaoInput = {
        wgs84Lng: location?.WGS84Lng,
        wgs84Lat: location?.WGS84Lat,
        sprache: language,
        land: country,
        fileType: fileType,
        staoProductCode: staoProductCode,
        street: location?.Street,
        number: location?.Number,
        zipCode: location?.ZipCode,
        city: location?.City
      };

      const { data, headers } = await getStao(staoInput, isDebugMode);
      const fileName = getFileNameFromContentDisposition(headers["content-disposition"]);
      downloadBlob(data, fileName);
    } catch (err) {
      console.log(err);
    } finally {      
      setIsLoading(false);
      setIsDebugMode(false);
    }
  }; 

  const isDisabledForNoLocation = !location;
  const isDisabledForInaccurateLocation = isDisabledForNoLocation || location.FpreIsAccurate === false;

  const [startButtonEwgIsLoading, setStartButtonEwgIsLoading] = useState<boolean>(Boolean(false));
  const [startButtonEfhIsLoading, setStartButtonEfhIsLoading] = useState<boolean>(Boolean(false));
  const [startButtonRenIsLoading, setStartButtonRenIsLoading] = useState<boolean>(Boolean(false));
  const [startButtonPotoIsLoading, setStartButtonPotoIsLoading] = useState<boolean>(Boolean(false));
  const [startButtonMwgIsLoading, setStartButtonMwgIsLoading] = useState<boolean>(Boolean(false));
  const [startButtonBueIsLoading, setStartButtonBueIsLoading] = useState<boolean>(Boolean(false));
  const [startButtonVerkIsLoading, setStartButtonVerkIsLoading] = useState<boolean>(Boolean(false));
  const [startButtonUmganaIsLoading, setStartButtonUmganaIsLoading] = useState<boolean>(Boolean(false));
  const [startButtonRegioIsLoading, setStartButtonRegioIsLoading] = useState<boolean>(Boolean(false));
  const [startButtonMikratIsLoading, setStartButtonMikratIsLoading] = useState<boolean>(Boolean(false));
  const [startButtonPfaIsLoading, setStartButtonPfaIsLoading] = useState<boolean>(Boolean(false));
  const [startButtonRm360IsLoading, setStartButtonRm360IsLoading] = useState<boolean>(Boolean(false));
  const [startButtonRenV2IsLoading, setStartButtonRenV2IsLoading] = useState<boolean>(Boolean(false));

  const [staoWpdfIsLoading, setStaoWpdfIsLoading] = useState<boolean>(Boolean(false));
  const [staoWxlsxIsLoading, setStaoWxlsxIsLoading] = useState<boolean>(Boolean(false));
  const [staoWwordIsLoading, setStaoWwordIsLoading] = useState<boolean>(Boolean(false));
  const [staoGpdfIsLoading, setStaoGpdfIsLoading] = useState<boolean>(Boolean(false));
  const [staoGxlsxIsLoading, setStaoGxlsxIsLoading] = useState<boolean>(Boolean(false));
  const [staoGwordIsLoading, setStaoGwordIsLoading] = useState<boolean>(Boolean(false));
  const [staoKpdfIsLoading, setStaoKpdfIsLoading] = useState<boolean>(Boolean(false));
  const [staoKxlsxIsLoading, setStaoKxlsxIsLoading] = useState<boolean>(Boolean(false));
  const [staoKwordIsLoading, setStaoKwordIsLoading] = useState<boolean>(Boolean(false));
  const [staoUeMakroPdfIsLoading, setStaoUeMakroPdfIsLoading] = useState<boolean>(Boolean(false));
  const [staoUeMakroXlsxIsLoading, setStaoUeMakroXlsxIsLoading] = useState<boolean>(Boolean(false));
  const [staoUeMakroWordIsLoading, setStaoUeMakroWordIsLoading] = useState<boolean>(Boolean(false));
  const [staoUeMikroPdfIsLoading, setStaoUeMikroPdfIsLoading] = useState<boolean>(Boolean(false));
  const [staoUeMikroXlsxIsLoading, setStaoUeMikroXlsxIsLoading] = useState<boolean>(Boolean(false));
  const [staoUeMikroWordIsLoading, setStaoUeMikroWordIsLoading] = useState<boolean>(Boolean(false));
  const [staoKartenPdfIsLoading, setStaoKartenPdfIsLoading] = useState<boolean>(Boolean(false));
  const [staoKartenXlsxIsLoading, setStaoKartenXlsxIsLoading] = useState<boolean>(Boolean(false));
  const [staoKartenWordIsLoading, setStaoKartenWordIsLoading] = useState<boolean>(Boolean(false));

  const [gemcWpdfIsLoading, setGemcWpdfIsLoading] = useState<boolean>(Boolean(false));
  const [gemcWxlsxIsLoading, setGemcWxlsxIsLoading] = useState<boolean>(Boolean(false));
  const [gemcGpdfIsLoading, setGemcGpdfIsLoading] = useState<boolean>(Boolean(false));
  const [gemcGxlsxIsLoading, setGemcGxlsxIsLoading] = useState<boolean>(Boolean(false));

  const [makratBpdfIsLoading, setMakratBpdfIsLoading] = useState<boolean>(Boolean(false));
  const [makratVpdfIsLoading, setMakratVpdfIsLoading] = useState<boolean>(Boolean(false));
  const [makratGewpdfIsLoading, setMakratGewpdfIsLoading] = useState<boolean>(Boolean(false));
  const [makratLogpdfIsLoading, setMakratLogpdfIsLoading] = useState<boolean>(Boolean(false));
  const [makratWpdfIsLoading, setMakratWpdfIsLoading] = useState<boolean>(Boolean(false));

  const isStaoWordLoading = () => {
    return staoWwordIsLoading || 
           staoGwordIsLoading || 
           staoKwordIsLoading || 
           staoUeMakroWordIsLoading || 
           staoUeMikroWordIsLoading || 
           staoKartenWordIsLoading;
  }
  
  const dashboardModuleCards = {
    bewertungEwg: {
      isDisabled: isDisabledForInaccurateLocation,
      isLocked: isModuleLocked("EWG"),
      startButtonIsLoading: startButtonEwgIsLoading,
      startButtonOnClick: () => { setStartButtonEwgIsLoading(true); initializeObject(2); }
    },
    bewertungEfh: {
      isDisabled: isDisabledForInaccurateLocation,
      isLocked: isModuleLocked("EFH"),
      startButtonIsLoading: startButtonEfhIsLoading,
      startButtonOnClick: () => { setStartButtonEfhIsLoading(true); initializeObject(3); }
    },
    bewertungRenditeimmobilie: {
      isDisabled: isDisabledForInaccurateLocation,
      isLocked: isModuleLocked("REN"),
      startButtonIsLoading: startButtonRenIsLoading,
      startButtonOnClick: () => {
        setStartButtonRenIsLoading(true);
        setIsNewLegacyObject(true);
        navigate(ApplicationRoutes.getPath("imbasLegacyErfassung", { imbasPath: "dcf" }));
      },
    },
    bewertungGrundstueck: {
      isDisabled: isDisabledForInaccurateLocation,
      isLocked: isModuleLocked("POTO"),
      startButtonIsLoading: startButtonPotoIsLoading,
      startButtonOnClick: () => {
        setStartButtonPotoIsLoading(true);
        setIsNewLegacyObject(true);
        navigate(ApplicationRoutes.getPath("imbasLegacyErfassung", { imbasPath: "poto" }))
      },
    },
    bewertungMietwohnung: {
      isDisabled: isDisabledForInaccurateLocation,
      isLocked: isModuleLocked("MWG"),
      startButtonIsLoading: startButtonMwgIsLoading,
      startButtonOnClick: () => {
        setStartButtonMwgIsLoading(true);
        setIsNewLegacyObject(true);
        navigate(ApplicationRoutes.getPath("imbasLegacyErfassung", { imbasPath: "mwg" }))
      },
    },
    bewertungBueroflaeche: {
      isDisabled: isDisabledForInaccurateLocation,
      isLocked: isModuleLocked("BUE"),
      startButtonIsLoading: startButtonBueIsLoading,
      startButtonOnClick: () => {
        setStartButtonBueIsLoading(true);
        setIsNewLegacyObject(true);
        navigate(ApplicationRoutes.getPath("imbasLegacyErfassung", { imbasPath: "bue" }))
      },
    },
    bewertungVerkaufsflaeche: {
      isDisabled: isDisabledForInaccurateLocation,
      isLocked: isModuleLocked("VERK"),
      startButtonIsLoading: startButtonVerkIsLoading,
      startButtonOnClick: () => {
        setStartButtonVerkIsLoading(true);
        setIsNewLegacyObject(true);
        navigate(ApplicationRoutes.getPath("imbasLegacyErfassung", { imbasPath: "verk" }))
      },
    },
    bewertungPortfolioanalyse: {
      isDisabled: false, // needs no location
      isLocked: isModuleLocked("PFA"),
      startButtonIsLoading: startButtonPfaIsLoading,
      startButtonOnClick: () => {
        setStartButtonPfaIsLoading(true);
        navigate(ApplicationRoutes.getPath("imbasLegacy", { imbasPath: "pfa" }))
      },
    },
    staoDebugFlag: {
      isHidden: process.env.REACT_APP_ENV  === "production" || !isDebugAdmin 
    },
    standortanalyseWohnen: {
      isDisabled: isDisabledForInaccurateLocation || 
                  staoWpdfIsLoading || 
                  staoWxlsxIsLoading || 
                  isStaoWordLoading(),
      isLocked: isModuleLocked("STAO") || country === Country.LI,
      pdfButtonIsLoading: staoWpdfIsLoading,
      pdfButtonOnClick: () => downloadStao(7, 1, setStaoWpdfIsLoading),
      excelButtonIsLoading: staoWxlsxIsLoading,
      excelButtonOnClick: () => downloadStao(7, 2, setStaoWxlsxIsLoading),
      wordButtonIsLoading: staoWwordIsLoading,
      wordButtonOnClick: () => downloadStao(7, 3, setStaoWwordIsLoading)
    },
    standortanalyseGeschaeft: {
      isDisabled: isDisabledForInaccurateLocation || 
                  staoGpdfIsLoading || 
                  staoGxlsxIsLoading || 
                  isStaoWordLoading(),
      isLocked: isModuleLocked("STAO") || country === Country.LI,
      pdfButtonIsLoading: staoGpdfIsLoading,
      pdfButtonOnClick: () => downloadStao(8, 1, setStaoGpdfIsLoading),
      excelButtonIsLoading: staoGxlsxIsLoading,
      excelButtonOnClick: () => downloadStao(8, 2, setStaoGxlsxIsLoading),
      wordButtonIsLoading: staoGwordIsLoading,
      wordButtonOnClick: () => downloadStao(8, 3, setStaoGwordIsLoading)
    },
    standortanalyseKomplett: {
      isDisabled: isDisabledForInaccurateLocation || 
                  staoKpdfIsLoading || 
                  staoKxlsxIsLoading || 
                  isStaoWordLoading(),
      isLocked: isModuleLocked("STAO") || country === Country.LI,
      pdfButtonIsLoading: staoKpdfIsLoading,
      pdfButtonOnClick: () => downloadStao(9, 1, setStaoKpdfIsLoading),
      excelButtonIsLoading: staoKxlsxIsLoading,
      excelButtonOnClick: () => downloadStao(9, 2, setStaoKxlsxIsLoading),
      wordButtonIsLoading: staoKwordIsLoading,
      wordButtonOnClick: () => downloadStao(9, 3, setStaoKwordIsLoading)
    },
    standortanalyseUebersichtMakrolage: {
      isDisabled: isDisabledForNoLocation || 
                  staoUeMakroPdfIsLoading || 
                  staoUeMakroXlsxIsLoading || 
                  isStaoWordLoading(),
      isLocked: isModuleLocked("STAO") || country === Country.LI,
      pdfButtonIsLoading: staoUeMakroPdfIsLoading,
      pdfButtonOnClick: () => downloadStao(3, 1, setStaoUeMakroPdfIsLoading),
      excelButtonIsLoading: staoUeMakroXlsxIsLoading,
      excelButtonOnClick: () => downloadStao(3, 2, setStaoUeMakroXlsxIsLoading),
      wordButtonIsLoading: staoUeMakroWordIsLoading,
      wordButtonOnClick: () => downloadStao(3, 3, setStaoUeMakroWordIsLoading)
    },
    standortanalyseUebersichtMikrolage: {
      isDisabled: isDisabledForInaccurateLocation || 
                  staoUeMikroPdfIsLoading || 
                  staoUeMikroXlsxIsLoading || 
                  isStaoWordLoading(),
      isLocked: isModuleLocked("STAO") || country === Country.LI,
      pdfButtonIsLoading: staoUeMikroPdfIsLoading,
      pdfButtonOnClick: () => downloadStao(6, 1, setStaoUeMikroPdfIsLoading),
      excelButtonIsLoading: staoUeMikroXlsxIsLoading,
      excelButtonOnClick: () => downloadStao(6, 2, setStaoUeMikroXlsxIsLoading),
      wordButtonIsLoading: staoUeMikroWordIsLoading,
      wordButtonOnClick: () => downloadStao(6, 3, setStaoUeMikroWordIsLoading)
    },
    standortanalyseKarten: {
      isDisabled: isDisabledForInaccurateLocation || 
                  staoKartenPdfIsLoading || 
                  staoKartenXlsxIsLoading || 
                  isStaoWordLoading(),
      isLocked: isModuleLocked("STAO") || country === Country.LI,
      pdfButtonIsLoading: staoKartenPdfIsLoading,
      pdfButtonOnClick: () => downloadStao(10, 1, setStaoKartenPdfIsLoading),
      excelButtonIsLoading: staoKartenXlsxIsLoading,
      excelButtonOnClick: () => downloadStao(10, 2, setStaoKartenXlsxIsLoading),
      wordButtonIsLoading: staoKartenWordIsLoading,
      wordButtonOnClick: () => downloadStao(10, 3, setStaoKartenWordIsLoading)
    },
    gemeindecheckWohnen: {
      isDisabled: isDisabledForNoLocation || 
                  gemcWpdfIsLoading || 
                  gemcWxlsxIsLoading,
      isLocked: isModuleLocked("GEMC_W"),
      pdfButtonIsLoading: gemcWpdfIsLoading,
      pdfButtonOnClick: () => downloadGemc(2, 1, setGemcWpdfIsLoading),
      excelButtonIsLoading: gemcWxlsxIsLoading,
      excelButtonOnClick: () => downloadGemc(2, 2, setGemcWxlsxIsLoading),
    },
    gemeindecheckGeschaeft: {
      isDisabled: isDisabledForNoLocation || 
                  gemcGpdfIsLoading || 
                  gemcGxlsxIsLoading,
      isLocked: isModuleLocked("GEMC_G"),
      pdfButtonIsLoading: gemcGpdfIsLoading,
      pdfButtonOnClick: () => downloadGemc(1, 1, setGemcGpdfIsLoading),
      excelButtonIsLoading: gemcGxlsxIsLoading,
      excelButtonOnClick: () => downloadGemc(1, 2, setGemcGxlsxIsLoading),
    },
    makrolageBuero: {
      isDisabled: isDisabledForNoLocation,
      isLocked: isModuleLocked("MAKRAT_B"),
      pdfButtonIsLoading: makratBpdfIsLoading,
      pdfButtonOnClick: () => downloadGemc(3, 1, setMakratBpdfIsLoading),
    },
    makrolageVerkauf: {
      isDisabled: isDisabledForNoLocation,
      isLocked: isModuleLocked("MAKRAT_V"),
      pdfButtonIsLoading: makratVpdfIsLoading,
      pdfButtonOnClick: () => downloadGemc(7, 1, setMakratVpdfIsLoading),
    },
    makrolageGewerbe: {
      isDisabled: isDisabledForNoLocation,
      isLocked: isModuleLocked("MAKRAT_GEW"),
      pdfButtonIsLoading: makratGewpdfIsLoading,
      pdfButtonOnClick: () => downloadGemc(4, 1, setMakratGewpdfIsLoading),
    },
    makrolageLogistik: {
      isDisabled: isDisabledForNoLocation,
      isLocked: isModuleLocked("MAKRAT_LOG"),
      pdfButtonIsLoading: makratLogpdfIsLoading,
      pdfButtonOnClick: () => downloadGemc(5, 1, setMakratLogpdfIsLoading),
    },
    makrolageMietwohnungen: {
      isDisabled: isDisabledForNoLocation,
      isLocked: isModuleLocked("MAKRAT_W"),
      pdfButtonIsLoading: makratWpdfIsLoading,
      pdfButtonOnClick: () => downloadGemc(6, 1, setMakratWpdfIsLoading),
    },
    weitereMetaanalysen: {
      isDisabled: false,
      isLocked: false,
      economyButton: {
        link: getLinkMetaanalyseKonjunktur(deriveIsoLocale(country, language)),
        target: "_blank",
        rel: "noopener noreferrer"
      },
      immoButton: {
        link: getLinkMetaanalyseImmobilien(deriveIsoLocale(country, language)),
        target: "_blank",
        rel: "noopener noreferrer"
      },
    },
    weitereMonitorRegulierung: {
      isDisabled: false,
      isLocked: isModuleLocked("MONREG"),
      startButtonOnClick: () => {
        window.open(
          `https://www.monreg.ch/autologin_i23.php?u=${fpre_userData?.Username}
          &token=9C698865-1554-47DE-ACF9-9C32541F9235`
          , '_blank');
      },
    },
    weitereRegionaleKarten: {
      isDisabled: isDisabledForNoLocation,
      isLocked: isModuleLocked("REGIO"),
      startButtonIsLoading: startButtonRegioIsLoading,
      startButtonOnClick: () => {
        setStartButtonRegioIsLoading(true);
        navigate(ApplicationRoutes.getPath("imbasLegacy", { imbasPath: "regio" }))
      },
    },
    weitereRealmatch360: {
      isDisabled: isDisabledForNoLocation,
      isLocked: isModuleLocked("RM360"),
      startButtonIsLoading: startButtonRm360IsLoading,
      startButtonOnClick: () => {
        setStartButtonRm360IsLoading(true);
        navigate(ApplicationRoutes.getPath("imbasLegacy", { imbasPath: "rm360" }))
      },
    },
    weitereUmgebungsanalyse: {
      isDisabled: isDisabledForInaccurateLocation,
      isLocked: isModuleLocked("UMGANA"),
      startButtonIsLoading: startButtonUmganaIsLoading,
      startButtonOnClick: () => {
        setStartButtonUmganaIsLoading(true);
        navigate(ApplicationRoutes.getPath("imbasLegacy", { imbasPath: "umgana" }))
      },
    },
    weitereMikrolage: {
      isDisabled: isDisabledForInaccurateLocation,
      isLocked: isModuleLocked("MIKRAT"),
      startButtonIsLoading: startButtonMikratIsLoading,
      startButtonOnClick: () => {
        setStartButtonMikratIsLoading(true);
        navigate(ApplicationRoutes.getPath("imbasLegacy", { imbasPath: "mikrat" }))
      },
    },
    weitereBewertungRenditeimmobilieV2: {
      isDisabled: isDisabledForInaccurateLocation,
      isLocked: isModuleLocked("RENV2"),
      startButtonIsLoading: startButtonRenV2IsLoading,
      startButtonOnClick: () => { setStartButtonRenV2IsLoading(true); initializeObject(8); }
    },
  }

  return (
    <PlasmicDashboard010
      root={{ ref }}
      {...dashboardModuleCards}
      {...props}
    />
  );
}

const Dashboard010 = React.forwardRef(Dashboard010_);
export default Dashboard010;
