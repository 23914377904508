// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import {
  PlasmicFormTextTableCell,
  DefaultFormTextTableCellProps
} from "./plasmic/imbas_marktwert_seiten/PlasmicFormTextTableCell";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { useNavigate, useParams } from "react-router-dom";
import { useObjectInfoContext } from "../context/ObjectInfoContext";
import { getCardId, getErfassungPathByNUTZ } from "../utils";

export interface FormTextTableCellProps extends DefaultFormTextTableCellProps {}

function FormTextTableCell_(
  { link, ...props }: FormTextTableCellProps,
  ref: HTMLElementRefOf<"div">
) {
  const { objectInfo } = useObjectInfoContext();
  const nutz = objectInfo?.NUTZ;
  const { objectId } = useParams();
  const navigate = useNavigate();

  return (
    <PlasmicFormTextTableCell 
      root={{ ref }} 
      {...props} 
      linkButton={{
        onClick: () => {
            if(link && nutz){
              const cardId  = getCardId(link, nutz);
              navigate(`${getErfassungPathByNUTZ(nutz, { objectId })}#${cardId}`, { state: { cardId, open: true } });
            }
        }
      }}
    />
  );
}

const FormTextTableCell = React.forwardRef(FormTextTableCell_);
export default FormTextTableCell;
