// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import {
  PlasmicComparablesRow,
  DefaultComparablesRowProps
} from "./plasmic/imbas_marktwert_seiten/PlasmicComparablesRow";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { t } from "i18next";
import { DynamicTextType, formatText } from "./custom/DynamicText";

export interface ComparablesRowProps extends DefaultComparablesRowProps {
}
function formatRank(rank: number | undefined): string
{
  if(rank)
     return rank.toString().length === 1 ? '0'+rank:rank.toString();
  return '-';
}
function formatVergleich(vergleich: number | undefined, type: DynamicTextType): string | undefined
{
  if(vergleich)
    return formatText(t, type, vergleich?.toString()) + '%' ;
  return '-';
}
function formatValue(value: number | string | undefined, type: DynamicTextType): string | undefined
{
  if(value)
    return formatText(t, type, value?.toString());
  return '-';
}

function ComparablesRow_(
  props: ComparablesRowProps,
  ref: HTMLElementRefOf<"div">
) {
  return (
    <PlasmicComparablesRow 
      root={{ ref }} 
      {...props} 
      rangSlot={formatRank(props.rang)}
      comparabilityBox={{ 
        props:{ 
          style: { 
            width:props.vergleich
          } 
        }
      }}
      vergleichText={formatVergleich(props.vergleich, DynamicTextType.Number) }
      preisSlot ={formatValue(props.preis, DynamicTextType.Currency)}
      preisM2Slot ={formatValue(props.preiSm2, DynamicTextType.Currency)}
      distanzSlot ={formatValue(props.distanz, DynamicTextType.Number)}
      strliegSlot ={formatValue(props.strlieg, DynamicTextType.Text)}
      plzliegSlot ={formatValue(props.plzlieg, DynamicTextType.Text)}
      ortliegSlot ={formatValue(props.ortlieg, DynamicTextType.Text)}
      datumSlot ={formatValue(props.datum, DynamicTextType.Text)}
      typSlot ={formatValue(props.typ, DynamicTextType.Text)}
      nfSlot ={formatValue(props.nf, DynamicTextType.Text)}
      volSlot ={formatValue(props.vol, DynamicTextType.Text)}
      landSlot={formatValue(props.land, DynamicTextType.Number)}
      zizSlot={formatValue(props.ziz, DynamicTextType.Number)}
      baujSlot ={formatValue(props.bauj, DynamicTextType.Text)}
      zustSlot ={formatValue(props.zust, DynamicTextType.Number)}
      mikroSlot ={formatValue(props.mikro, DynamicTextType.Number)}
      standSlot ={formatValue(props.stand, DynamicTextType.Number)}
      etageSlot ={formatValue(props.etage, DynamicTextType.Text)}
      idSlot ={formatValue(props.id, DynamicTextType.Text)}
    />
  );
}

const ComparablesRow = React.forwardRef(ComparablesRow_);
export default ComparablesRow;
