import React, { useMemo } from "react";
import {
  DefaultProcessBreadCrumbProps,
  PlasmicProcessBreadCrumb,
  PlasmicProcessBreadCrumb__OverridesType
} from "./plasmic/imbas_23_fpre/PlasmicProcessBreadCrumb";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { matchPath, useLocation, useNavigate, useParams } from "react-router-dom";
import { ApplicationRoutes } from "../ApplicationRoutes";
import { useObjectInfoContext } from "../context/ObjectInfoContext";
import { getErfassungPathByNUTZ } from "../utils";

export interface ProcessBreadCrumbProps extends DefaultProcessBreadCrumbProps {
}

function ProcessBreadCrumb_(props: ProcessBreadCrumbProps, ref: HTMLElementRefOf<"div">) {
  const navigate = useNavigate();
  const location = useLocation();
  const { objectInfo } = useObjectInfoContext();
  const NUTZ = objectInfo?.NUTZ;
  const { objectId = "plasmic" } = useParams();

  const breadCrumbMapping: { [key in keyof PlasmicProcessBreadCrumb__OverridesType]: string } = useMemo(() => ({
    "navButtonErfassung": getErfassungPathByNUTZ(NUTZ!, { objectId }),
    "navButtonMarktwert": ApplicationRoutes.getPath("ewg020Marktwert", { objectId }),
    "navButtonBericht": ApplicationRoutes.getPath("ewg030Bericht", { objectId }),
  }), [objectId, NUTZ]);
    
  const navButtons = useMemo(() => {
    let activeFound = false; // Disables all buttons after the active one

    return Object.entries(breadCrumbMapping).reduce((acc, [key, path]) => {
      const isActive = matchPath(location.pathname, path) !== null;
      const isDisabled = activeFound;
      if (isActive) {
        activeFound = true;
      }
      return { ...acc, [key]: { onClick: isDisabled ? undefined : () => navigate(path), isActive, isDisabled } };
    }, {});
  }, [breadCrumbMapping]);

  return (
    <PlasmicProcessBreadCrumb
      root={{ ref }}
      {...props}
      {...navButtons}
    />
  );
}

const ProcessBreadCrumb = React.forwardRef(ProcessBreadCrumb_);
export default ProcessBreadCrumb;
