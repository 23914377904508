import React from "react";
import { DefaultFormInputTableCellProps, PlasmicFormInputTableCell } 
        from "./plasmic/imbas_marktwert_seiten/PlasmicFormInputTableCell";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { useObjectInfoContext } from "../context/ObjectInfoContext";
import { useNavigate, useParams } from "react-router-dom";
import { getCardId, getErfassungPathByNUTZ } from "../utils";
import { ApplicationRoutes } from "../ApplicationRoutes";

export interface FormInputTableCellProps extends DefaultFormInputTableCellProps {
  onLinkButtonClick?: (event: MouseEvent) => void;
}

function FormInputTableCell_(
  { link, onLinkButtonClick, ...props }: FormInputTableCellProps,
  ref: HTMLElementRefOf<"div">
) {  
  const { objectInfo } = useObjectInfoContext();
  const nutz = objectInfo?.NUTZ;
  const { objectId } = useParams();
  const navigate = useNavigate();

  onLinkButtonClick = ()=>{
      if(link && nutz){
        switch (link) {
          case 'NHK':
            navigate(
              `${ApplicationRoutes.getPath("ewg020MarktwertHerleitungNHK", { objectId: objectId ?? "" })}#nhk`
            );
            break; 
          default:{     
            const cardId = getCardId(link, nutz);
            navigate(`${getErfassungPathByNUTZ(nutz, { objectId })}#${cardId}`, { state: { cardId, open: true } });
            break; 
          }
        }
      }
    }
  return (
    <PlasmicFormInputTableCell
      root={{ ref }}
      {...props}
      formSuggestionGroup={{ onLinkButtonClick }}
    />
  );
}

const FormInputTableCell = React.forwardRef(FormInputTableCell_);
export default FormInputTableCell;

