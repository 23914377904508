import * as React from "react";
import {
  PlasmicDcfObjectRow,
  DefaultDcfObjectRowProps
} from "./plasmic/imbas_dcf_seiten/PlasmicDcfObjectRow";
import { HTMLElementRefOf } from "@plasmicapp/react-web";

export interface DcfObjectRowProps extends DefaultDcfObjectRowProps {
  index: number
}

function DcfObjectRow_({ index, ...props }: DcfObjectRowProps, ref: HTMLElementRefOf<"div">) {
  
  return (
    <PlasmicDcfObjectRow 
      root={{ ref }} 
      {...props}
      index={index + 1}
      selectedHauptnutzungen={{
        name: `Objekte.[${index}].NUTZs`
      }}
      referenz={{
        name: `Objekte.[${index}].Referenz`
      }}
      egid={{
        name: `Objekte.[${index}].EGID`
      }}
      baujahr={{
        name: `Objekte.[${index}].Baujahr`
      }}
      isEwg={{
        name: `Objekte.[${index}].IsEWG`,
      }}
      addressSuggestor={{
        fullAddress: props.adresse
      }}
      deleteButton={{ onClick: props.onDelete }}
    />
  ) 
}

const DcfObjectRow = React.forwardRef(DcfObjectRow_);
export default DcfObjectRow;
