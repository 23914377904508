
import * as React from "react";
import {
  PlasmicRemmsActionField,
  DefaultRemmsActionFieldProps
} from "./plasmic/imbas_marktwert_seiten/PlasmicRemmsActionField";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { useEffect, useState } from "react";


export interface RemmsActionFieldProps extends DefaultRemmsActionFieldProps { }


function RemmsActionField_(
  props: RemmsActionFieldProps,
  ref: HTMLElementRefOf<"div">
) {
  const [isVisible, setIsVisible] = useState<boolean>(true);

  useEffect(() => {
    if (props.hide) {
      setIsVisible(false);
    }
  }, [props.hide]);


  return (
    <>
      {
        isVisible &&
        <PlasmicRemmsActionField
          gebhuelStack={{ ref }}
          {...props}
          nettoinvestInfoCard={{ infoCardValue: props.nettoinvest }}
          co2InfoCard={{ infoCardValue: props.co2 }}
          title={props.title}
        />
      }
    </>
  );
}

const RemmsActionField = React.forwardRef(RemmsActionField_);
export default RemmsActionField;
