import React, { cloneElement, createElement, isValidElement, ReactElement } from "react";
import { DefaultFormGroupProps, PlasmicFormGroup } from "./plasmic/imbas_23_fpre/PlasmicFormGroup";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { useWiredFormField } from "./custom/WiredFormField";
import TextInput from "./TextInput";
import { RenderControlContext } from "../utils";
import { useObjectInfoContext } from "../context/ObjectInfoContext";
import { Country } from "../context/RegionContext";
import { FieldError, useFormContext } from "react-hook-form";
import { SymbolCounterProps } from "./SymbolCounter";
import { useTranslation } from "react-i18next";
import { useDataStore } from "../store/DataStoreProvider";
import { get } from "lodash";
import { useDynamicDropdownValue } from "../context/DynamicDropdownValuesContext";
import { AlgoButtonProps } from "./AlgoButton";

export interface FormGroupProps extends DefaultFormGroupProps {
  algoButton: AlgoButtonProps
}

function FormGroup_(props: FormGroupProps, ref: HTMLElementRefOf<"div">) {
  const { name = "", input, symbolCounter, isRequired, isHiddenInCh, isHiddenInDe } = props;
  const { t } = useTranslation();
  const fieldError = useFormContext()?.formState.errors[name] as FieldError | undefined;

  const { setValue } = useFormContext();  
  const { objectInfo } = useObjectInfoContext();

  const { dataStore } = useDataStore();
  const algoValue = props.algoName && dataStore 
    ? get(dataStore, props.algoName) ?? ''
    : '';
    
  let displayName = algoValue;
  const dynamicDropdownValueItem = useDynamicDropdownValue(name, algoValue);
  if(dynamicDropdownValueItem)
  {
    displayName = t(dynamicDropdownValueItem.Name, { ns: "plasmic" });
  }

  const forwardedProps : FormGroupProps = {
     ...props,
    input: useWiredFormField(name, input as ReactElement ?? createElement(TextInput), !!isRequired),
    state: fieldError ? "error" : undefined,
    stateText: fieldError && 
       (fieldError.message || t(`validation.${fieldError.type}`, { defaultValue: t("validation.default") })),
    symbolCounter: 
      isValidElement<SymbolCounterProps>(symbolCounter) && 
      cloneElement(symbolCounter, { name }),
    algoValue: displayName ?? '',
    hasAlgoButton: props.hasAlgoButton && props.algoName && algoValue ? true : false,
    algoButton: { 
      onClick: () => 
        setValue(name, algoValue) 
    }
  };

  const currentCountry = objectInfo?.Location?.LAND || Country.CH;
  if (
    (isHiddenInCh && [Country.CH, Country.LI].includes(currentCountry)) ||
    (isHiddenInDe && [Country.DE, Country.LU].includes(currentCountry))
  ) {
    return null;
  }

  return props.isRequired || props.isShownInitially ? (
    <PlasmicFormGroup 
      root={{ ref }} 
      {...forwardedProps}
    />
  ) : (
    <RenderControlContext.Consumer>
      {({ hidden }) => <PlasmicFormGroup isHidden={hidden} root={{ ref }} {...forwardedProps} />}
    </RenderControlContext.Consumer>
  );
}

const FormGroup = React.forwardRef(FormGroup_);
export default FormGroup;
