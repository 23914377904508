import * as React from "react";
import {
  PlasmicDcfPlotGroup,
  DefaultDcfPlotGroupProps
} from "./plasmic/imbas_dcf_seiten/PlasmicDcfPlotGroup";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { useFieldArray, useFormContext } from "react-hook-form";
import { components } from "../api/types";
import { useCallback, useMemo } from "react";
import DcfPlotRow from "./DcfPlotRow";

export type DcfParzelle = components["schemas"]["ImbasDcfParzelle"];

export interface DcfPlotGroupProps extends DefaultDcfPlotGroupProps {
  plotFactory: (referenz: string) => object
}

function DcfPlotGroup_(
  { plotFactory, ...props }: DcfPlotGroupProps, 
  ref: HTMLElementRefOf<"div">) {
  
  const name = "Parzellen";
  const { control, getValues } = useFormContext();
  const { fields, append, remove } = useFieldArray({ control, name });

  
  const handleDelete = useCallback((index: number) => {
      remove(index)
  }, [getValues, remove]);

  const items = useMemo(() => fields.map((field, index) => {
    return (
      <DcfPlotRow
        key={field.id}
        index={index}
        onDelete={() => handleDelete(index)}
      />
    );
  }), [fields, name, handleDelete]);

  return (
    <PlasmicDcfPlotGroup 
      root={{ ref }} 
      {...props} 
      items={items}
      addButton={{
        onClick: () => {
          append(plotFactory(`Parzelle ${items.length + 1}`))
        }
      }}
    />
  );
}

const DcfPlotGroup = React.forwardRef(DcfPlotGroup_);
export default DcfPlotGroup;
