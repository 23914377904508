import React, 
       {  createContext, 
          Dispatch, 
          PropsWithChildren, 
          SetStateAction, 
          useCallback, 
          useContext, 
          useEffect, 
          useRef, 
          useState } from "react";
import { DefaultProcessHeaderProps, PlasmicProcessHeader } from "./plasmic/imbas_23_fpre/PlasmicProcessHeader";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { useObjectInfoContext } from "../context/ObjectInfoContext";

const DELTA = 15;

// these values are defined in Plasmic Studio (in pixels)
const LAPTOP_BREAK_POINT = 1400;
const SCROLL_NAV_HEIGHT = 55;

export interface ProcessHeaderProps extends DefaultProcessHeaderProps {
}

const ProcessHeaderContext = createContext<{
  showProcessHeader: boolean,
  setShowProcessHeader: Dispatch<SetStateAction<boolean>>,
}>({
  showProcessHeader: true,
  setShowProcessHeader: () => undefined
});

export const useProcessHeaderContext = () => useContext(ProcessHeaderContext);

export const ProcessHeaderProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [showProcessHeader, setShowProcessHeader] = useState(true);
  return (
    <ProcessHeaderContext.Provider value={{ showProcessHeader, setShowProcessHeader }}>
      {children}
    </ProcessHeaderContext.Provider>
  );
};
  
  
function ProcessHeader_(
  props: ProcessHeaderProps,
  ref: HTMLElementRefOf<"div">
) {
  const processHeaderRef = useRef<HTMLDivElement>(null);
  const [prevScrollPos, setPrevScrollPos] = useState<number>(0);
  const [showAddressSearchBar, setShowAddressSearchBar] = useState(false);
  const { showProcessHeader, setShowProcessHeader } = useProcessHeaderContext();
  const { objectInfo } = useObjectInfoContext();

  useEffect(() => {
      setShowAddressSearchBar(false);
  }, [objectInfo]);

  const handleScroll = useCallback(() => {
    const currentScrollPos = window.scrollY;

    const prevScroll = prevScrollPos + (!showAddressSearchBar? + 150 : 0);
    if (Math.abs(prevScroll - currentScrollPos) <= DELTA) {
      return;
    }
    const test: boolean = prevScrollPos >= currentScrollPos
    setShowProcessHeader(test);
    setPrevScrollPos(currentScrollPos);

  }, [prevScrollPos]);


  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
      <div
        ref={processHeaderRef}
        style={{
          zIndex: 5,
          top: 0,
          position: "sticky",
          transform: `translateY(${
            showProcessHeader ? 
              "0" : (window.innerWidth < LAPTOP_BREAK_POINT ? `calc(-100% + ${SCROLL_NAV_HEIGHT}px)` : "-100%")
          })`,
          transition: "transform 0.3s ease-in-out",
        }}
      >
        <PlasmicProcessHeader 
          root={{ ref }} 
          {...props} 
          showAddressSearchBar={showAddressSearchBar}
          editAdress={ (e) => setShowAddressSearchBar(!showAddressSearchBar) }
        />
      </div>
  );
}

const ProcessHeader = React.forwardRef(ProcessHeader_);
export default ProcessHeader;
