// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Icon2IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Icon2Icon(props: Icon2IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 16 16"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <g fill={"currentColor"}>
        <path
          d={
            "M15 3h-3V1a1 1 0 00-1-1H5a1 1 0 00-1 1v2H1a1 1 0 000 2h14a1 1 0 000-2zM6 3V2h4v1z"
          }
        ></path>

        <path
          d={"M2.055 7l.45 8.1a1 1 0 001 .9h9a1 1 0 00.995-.9l.45-8.1z"}
          fill={"currentColor"}
        ></path>
      </g>
    </svg>
  );
}

export default Icon2Icon;
/* prettier-ignore-end */
