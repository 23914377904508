import React from "react";
import { isEmpty, isNaN, isNil, isObjectLike, negate, overSome } from "lodash";
import { ApplicationRoutes } from "./ApplicationRoutes";
import { LocationDetail } from "./api/endpoints/location";
import { components } from "./api/types";

export const isEmptyUndefinedOrNull = (value: string | null | undefined): boolean => {
  return value === '' || value === null || value === undefined;
};

export const isNumber = (value: string | null | undefined): boolean => {
  return !Number.isNaN(Number(value));
};

export function getStateForRetrievedValue(apiValue: number): "neutral" | "success" | "warning" | "error" {
  switch (apiValue) {
    case 0:
    case 1:
      return "error";
    case 2:
      return "warning";
    case 3:
    case 4:
    case 5:
      return "success";
    default:
      return "neutral";
  }
}

export const scrollElementIntoView = async (elementId: string, isHorizontalScrollNav: boolean): Promise<void> => {
  const element = document.getElementById(elementId);
  if (element === null) return;

  const top = element.getBoundingClientRect().top;
  let elementTop = top + -199;
  if(isHorizontalScrollNav)// Mobile/Laptop
    elementTop = top + (top < 0? -199 : -55);//<-- 199 Process Header , 55 ScrollNav 
  else
    elementTop = top + (top < 0? -152 : 0);//<-- 152 Process Header

  const offsetTop = window.scrollY + elementTop;
  window.scrollTo({ top: offsetTop, behavior: "smooth" });

  if (decodeURIComponent(window.location.hash.substring(1)) === elementId) return
  window.history.replaceState(null, "", `#${encodeURIComponent(elementId)}`);
};

/**
 * Returns the textual value of a component used in Plasmic up to the first child.
 * Can be used to get the text value of a button, for example.
 */
export function getValOfPlasmicComponent(child: React.ReactNode) {
  if (typeof child === "string") return child;
  if (!React.isValidElement(child)) return undefined;

  try {
    if (typeof child.props.value == "string") return child.props.value;
    if (typeof child.props.text == "string") return child.props.text;
    if (typeof (child.props.text as React.ReactElement).props.children == "string")
      return (child.props.text as React.ReactElement).props.children;
  } catch (_e) {
    return undefined;
  }
}

export const RenderControlContext = React.createContext({
  hidden: false,
});

export const isPresent = negate(overSome(
  isNil,
  isNaN,
  v => v?.length <= 0,
  v => isObjectLike(v) && isEmpty(v),
));


// This is for manually triggering input change events on refs. Source:
// https://stackoverflow.com/questions/23892547/what-is-the-best-way-to-trigger-onchange-event-in-react-js#46012210
type InputChangeTriggerable = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
export const triggerInputChange = (node: InputChangeTriggerable, value: string) => {
  const nativeInputValueSetter = Object.getOwnPropertyDescriptor(Object.getPrototypeOf(node), "value")?.set;
  const event = new Event("input", { bubbles: true });
  nativeInputValueSetter?.call(node, value);
  node.dispatchEvent(event);
};

export const parseLocationString = <T extends LocationDetail>(location?: T | null): string => {
  if (!location) return "";

  return [[location.Street, location.Number], [location.ZipCode, location.City]].map(
    (arr) => arr.filter(Boolean).join(" ")
  ).filter(Boolean).join(", ")
};

export const readableFileSize = (bytes?: number, decimals?: number): string => {
  if (!bytes) return "0 Bytes";
  const k = 1024,
    dm = decimals || 2,
    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const getImageDimensions = (file: File) => new Promise<{ width: number, height: number }>((resolve, reject) => {
  const img = new Image();
  img.src = URL.createObjectURL(file);
  img.onload = () => {
    URL.revokeObjectURL(img.src);
    resolve({ width: img.width, height: img.height });
  };
  img.onerror = reject;
});

export const base64ToObjectUrl = (base64: string): string => {
  const byteCharacters = window.atob(base64);
  const byteArray = new Uint8Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteArray[i] = byteCharacters.charCodeAt(i);
  }
  const blob = new Blob([byteArray], { type: "image" });
  return URL.createObjectURL(blob);
};

export function createHOC<T extends object>(WrapperComponent: React.ComponentType<T>) {
  return function (providerProps: T) {
    return function <P extends object>(Component: React.ComponentType<P>) {
      const HOCComponent: React.FC<P> = (props) => (
        <WrapperComponent {...providerProps}>
          <Component {...props} />
        </WrapperComponent>
      );
      return HOCComponent;
    };
  }
}


export const downloadBlob = (
  blob: Blob,
  fileName?: string,
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const linkEl = document.createElement("a");
  linkEl.href = URL.createObjectURL(blob);
  linkEl.download = fileName ?? "unknown";
  document.body.appendChild(linkEl);
  linkEl.click();
  setTimeout(() => {
    document.body.removeChild(linkEl);
    URL.revokeObjectURL(linkEl.href);
    if (setIsLoading)
      setIsLoading(false)
  }, 0);

};

export const getFileNameFromContentDisposition = (contentDispositionHeader: string | null | undefined): string => {
  if (contentDispositionHeader) {
    const fileNameMatch = contentDispositionHeader.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
    if (fileNameMatch && fileNameMatch[1]) {
      return fileNameMatch[1].replace(/['"]/g, "");
    }
  }
  return "unknown";
};

enum NUTZ {
  DCF = 1,
  EWG = 2,
  EFH = 3,
  MWG = 4,
  BUE = 5,
  VERK = 6,
  POTO = 7,
  RENV2 = 8
}

export const getErfassungPathByNUTZ = (nutz: components["schemas"]["NUTZ"], params: Partial<{ objectId: string }>) => {
  switch (nutz) {
    case 2:
      return ApplicationRoutes.getPath("ewg010Erfassung", { objectId: params.objectId ?? "" });
    case 3:
      return ApplicationRoutes.getPath("efh010Erfassung", { objectId: params.objectId ?? "" });
    case 1://dcf/ren
    case 4://mwg
    case 5://bue
    case 6://verk
    case 7://poto
      return ApplicationRoutes.getPath("imbasLegacyErfassung", { imbasPath: NUTZ[nutz].toString().toLowerCase() });
    case 8://ren v2
      return ApplicationRoutes.getPath("dcf010Grundlagen", { objectId: params.objectId ?? "" });
    default:
      throw new Error(`No redirect path for NUTZ ${nutz} defined`);
  }
};

export const getCardId = (fieldName: string, NUTZ: NUTZ): string => {
  switch (fieldName) {
    case 'BAUJ':
    case 'BAUJTECH':
    case 'GEBKON':
    case 'DACHC':
    case 'GEB_ANZAHL_GESCHOSSE_OBERIRDISCH':
      switch (NUTZ) {
        case 2:
          return 'GuW/Gebäude';
        case 3:
          return 'GuW/Typologie';
        default:
          throw new Error(`not implemented ${NUTZ} and ${fieldName}`)
      }
    case 'ERNFOND':
      return 'GuW/Administratives';
    case 'SERVIT_ALTLASTEN':
      return 'Grundstück/Altlasten';
    case 'SERVIT_WERTKORR':
      return 'Grundstück/Servitute';
    case 'SERVIT_DENKMAL':
      return 'Grundstück/Denkmalschutz';
    case 'MW_PARK_UI':
      return 'Parkierung';
    case 'LAND':
      return 'Grundstück/BE';
    case 'WERTQEWG':
      return 'GuW/Administratives';
    case 'BGF':
    case 'WOHNFL':
      switch (NUTZ) {
        case 2:
          return 'GuW/Wohnung';
        case 3:
          return 'GuW/Typologie';
        default:
          throw new Error(`not implemented ${NUTZ} and ${fieldName}`)
    }
    default:
      throw new Error(`not implemented ${NUTZ} and ${fieldName}`)
  }
}

export function getLoadingButton(title: string, color?: string) {
  const css = `.nc-loop-bars-16-icon-f *{--animation-duration:0.8s;transform-origin:8px 8px;
    animation:nc-loop-bars-anim var(--animation-duration) infinite}.nc-loop-bars-16-icon-f 
    :nth-child(2){opacity:1;transform:scaleY(1)}.nc-loop-bars-16-icon-f :nth-child(1),.nc-loop-bars-16-icon-f 
    :nth-child(3){opacity:.3;transform:scaleY(.5);animation-delay:calc(var(--animation-duration)/-2)}@keyframes 
    nc-loop-bars-anim{0%,100%{opacity:1;transform:scaleY(1)}50%{opacity:.3;transform:scaleY(.5)}}`;

  return (
    <svg height="16" width="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <title>{title}</title>
      <g fill={color ?? "#FFFFFF"}>
        <g className="nc-loop-bars-16-icon-f">
          <path d="M0 2h2v12H0z" fill={color ?? "#FFFFFF"} />
          <path d="M7 2h2v12H7z" />
          <path d="M14 2h2v12h-2z" fill={color ?? "#FFFFFF"} />
        </g>
        <style>{css}</style>
      </g>
    </svg>
  );
}

export const isWebpackRequest = (e: MessageEvent): boolean => {
  if (e.data.type && e.data.type.includes("webpack"))
    return true;
  if (e.data.includes("webpack"))
    return true;
  return false;
}

export const getLinkMetaanalyseKonjunktur = (isoLocale: string) => {
  const defaultLink = "https://fpre.ch/wp-content/uploads/meta_kon.pdf";
  const languageLinkMap: { [key: string]: string; } = {
    "de-CH": "https://fpre.ch/wp-content/uploads/meta_kon.pdf",
    "fr-CH": "https://fr.fpre.ch/wp-content/uploads/meta_ac.pdf",
    "it-CH": "https://it.fpre.ch/wp-content/uploads/meta_con.pdf",
    "en-US": "https://fpre.ch/wp-content/uploads/meta_eco.pdf",
    "de-DE": "https://fahrlaenderpartner.de/wp-content/uploads/meta_immo_de-de.pdf"
  }
  return (languageLinkMap[isoLocale] || defaultLink);
}

export const getLinkMetaanalyseImmobilien = (isoLocale: string) => {
  const defaultLink = "https://fpre.ch/wp-content/uploads/meta_immo_ch_de.pdf";
  const languageLinkMap: { [key: string]: string; } = {
    "de-CH": "https://fpre.ch/wp-content/uploads/meta_immo_ch_de.pdf",
    "fr-CH": "https://fr.fpre.ch/wp-content/uploads/meta_immo_ch_fr.pdf",
    "it-CH": "https://it.fpre.ch/wp-content/uploads/meta_immo_ch_it.pdf",
    "en-US": "https://fpre.ch/wp-content/uploads/meta_real_estate_ch.pdf",
    "de-DE": "https://fahrlaenderpartner.de/wp-content/uploads/ausbild_immo_de.pdf"
  }
  return (languageLinkMap[isoLocale] || defaultLink);
}
