import * as React from "react";
import {
  PlasmicCardBewirtschaftungskostenHerleiten,
  DefaultCardBewirtschaftungskostenHerleitenProps
} from "./plasmic/imbas_marktwert_seiten/PlasmicCardBewirtschaftungskostenHerleiten";
import { useFormContext } from "react-hook-form";
import { useDataStore } from "../store/DataStoreProvider";
import { MarktwertInput } from "../api/endpoints/marktwert";
export interface CardBewirtschaftungskostenHerleitenProps
  extends DefaultCardBewirtschaftungskostenHerleitenProps {}

  function getPropertyName<T extends object>(o: T, expression: (x: { [Property in keyof T]: string }) => string) {
    const res = {} as { [Property in keyof T]: string };
    Object.keys(o).map(k => res[k as keyof T] = k);
    return expression(res);
  }

function CardBewirtschaftungskostenHerleiten(
  props: CardBewirtschaftungskostenHerleitenProps
) {
  const { setValue } = useFormContext();  
  const { dataStore: { marktwertCalc, marktwertInput } } = useDataStore();
  
  return (
    <PlasmicCardBewirtschaftungskostenHerleiten 
      {...props} 
      bewirtschaftungskostenUebernehmen={{
        onClick: async () => { 
          const BWK_WOHN_UI = getPropertyName(marktwertInput as MarktwertInput, propName => propName.BWK_WOHN_UI!)
          setValue(BWK_WOHN_UI, marktwertCalc?.Bewirtschaftungskosten);
        } }
      }
    />);
}
export default CardBewirtschaftungskostenHerleiten;
