// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import {
  PlasmicRemmsRatingCard,
  DefaultRemmsRatingCardProps
} from "./plasmic/imbas_marktwert_seiten/PlasmicRemmsRatingCard";
import { HTMLElementRefOf } from "@plasmicapp/react-web";

export interface RemmsRatingCardProps extends DefaultRemmsRatingCardProps { }

function calcProgress(rating: number | undefined): string {
  if (rating)
    return (rating * 100 / 5).toString() + '%';
  return '0%';
}

function RemmsRatingCard_(
  props: RemmsRatingCardProps,
  ref: HTMLElementRefOf<"div">
) {
  return (
    <PlasmicRemmsRatingCard
      root={{ ref }}
      {...props}
      state={props.rating !== undefined && props.rating < 3 ? "danger" : "_default"}
      ratingText={props.rating?.toFixed(1)}
      ratingProgress={{
        props: {
          style: {
            width: calcProgress(props.rating)
          }
        }
      }}
    />
  );
}

const RemmsRatingCard = React.forwardRef(RemmsRatingCard_);
export default RemmsRatingCard;
