import * as React from "react";
import {
  PlasmicAddressSearch,
  DefaultAddressSearchProps
} from "./plasmic/imbas_23_fpre/PlasmicAddressSearch";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { useAddressPrediction } from "../hooks/useAddressPrediction";
import { useCallback, useEffect, useMemo, useState } from "react";
import { AddressPrediction, getLocationDetail } from "../api/endpoints/location";
import { toast } from "react-toastify";
import { AutocompleteResultDTO } from "./AutocompleteTextInput";
import { useFormContext } from "react-hook-form";
import { components } from "../api/types";
import { useRegionContext } from "../context/RegionContext";

export interface AddressSearchProps extends DefaultAddressSearchProps { }

function AddressSearch_(
  props: AddressSearchProps,
  ref: HTMLElementRefOf<"div">
) {

  const { strasse, strasseNr, plz, ort } = props;
  const { search, addressPredictions } = useAddressPrediction();
  const { setValue } = useFormContext();
  const [searchInputValue, setSearchInputValue] = useState<string>("");
  const { language } = useRegionContext();

  useEffect(() => {
    search(searchInputValue);
  }, [search, searchInputValue]);

  function setAddress(data: {
    Street?: string | null | undefined;
    Number?: string | null | undefined;
    ZipCode?: string | null | undefined;
    City?: string | null | undefined;
    LAND: components["schemas"]["LAND"];
    WGS84Lat?: number | null | undefined;
    WGS84Lng?: number | null | undefined;
    Precision?: components["schemas"]["Precision"];
    FpreIsAccurate?: boolean | undefined;
  }): void {
    setValue(strasse!, data.Street);
    setValue(strasseNr!, data.Number);
    setValue(plz!, data.ZipCode);
    setValue(ort!, data.City);
  }

  const handleSearch = useCallback((value: string | null) => {
    setSearchInputValue(value ?? "");
  }, []);

  const handleDropdownSelection = useCallback(async (selectedAddress: AddressPrediction) => {
    const placeId = selectedAddress.PlacesID;
    if (!placeId) return;

    getLocationDetail(placeId, language)
      .then(({ data }) => setAddress(data))
      .catch((_err) => toast.error(_err, { toastId: "network-error" }));
  }, []);

  const autocompleteResults: Array<AutocompleteResultDTO> = useMemo(() => (
    addressPredictions.map((addressPrediction) => ({
      label: addressPrediction.Address ?? "",
      onClick: () => handleDropdownSelection(addressPrediction),
      isRegion: !addressPrediction.FpreIsAccurate,
    }))
  ), [addressPredictions, handleDropdownSelection]);

  return (
    <PlasmicAddressSearch
      root={{ ref }}
      {...props}

      searchInput={{
        value: props.fullAddress ?? searchInputValue,
        onChange: handleSearch,
        results: autocompleteResults,
      }}
    />);
}

const AddressSearch = React.forwardRef(AddressSearch_);
export default AddressSearch;
