import * as React from "react";
import {
  PlasmicRemmsComponent,
  DefaultRemmsComponentProps
} from "./plasmic/imbas_marktwert_seiten/PlasmicRemmsComponent";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { REMMSData, getREMMS, getReport } from "../api/endpoints/remms";
import { downloadBlob, getCardId, getFileNameFromContentDisposition } from "../utils";
import { useEffect, useState } from "react";
import { Country, useRegionContext } from "../context/RegionContext";
import { useParams } from "react-router-dom";
import { useProcessCardsCtx } from "../context/ProcessCardsContext";
import { useIdTokenClaims } from "../auth/authUtils";
import { useObjectInfoContext } from "../context/ObjectInfoContext";

export interface RemmsComponentProps extends DefaultRemmsComponentProps { }


const downloadReport = (id: string | null | undefined, setIsLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
  setIsLoading(true);
  return getReport(id).then(({ data, headers }) => {
    const fileName = getFileNameFromContentDisposition(headers["content-disposition"]);
    downloadBlob(data, fileName, setIsLoading);
  });
};
const constructHandlungsfelder = (remmsData: REMMSData | undefined) => {
  return (
    <ul>
      {remmsData?.CO2BILANZTEXT9 && (<li>{remmsData.CO2BILANZTEXT9}</li>)}
      {remmsData?.CO2BILANZTEXT10 && (<li>{remmsData.CO2BILANZTEXT10}</li>)}
      {remmsData?.CO2BILANZTEXT11 && (<li>{remmsData.CO2BILANZTEXT11}</li>)}
      {remmsData?.CO2BILANZTEXT12 && (<li>{remmsData.CO2BILANZTEXT12}</li>)}
      {remmsData?.CO2BILANZTEXT13 && (<li>{remmsData.CO2BILANZTEXT13}</li>)}
    </ul>
  );
}

function RemmsComponent_(
  props: RemmsComponentProps,
  ref: HTMLElementRefOf<"div">
) {
  const [isLoadingReport, setIsLoadingReport] = useState<boolean>(Boolean(false));
  const [isLoading, setIsLoading] = useState<boolean>(Boolean(false));
  const [isFailed, setIsFailed] = useState<boolean>(Boolean(false));
  const { objectId = "plasmic" } = useParams();
  const { language, country } = useRegionContext();
  const { dispatch } = useProcessCardsCtx();
  const [remmsData, setREMMSData] = useState<REMMSData | undefined>();
  const allowedModules = useIdTokenClaims()?.fpre_allowedModules?.ModulInfo || [];
  const remmsModuleActive = allowedModules.some(
    ({ ModulName, Land }) => ModulName === "REMMS" && Country[Land] === country
  );
  const { objectInfo } = useObjectInfoContext();
  const NUTZ = objectInfo?.NUTZ;

  useEffect(() => {
    if (remmsModuleActive) {
      setIsLoading(true);
      getREMMS(objectId, language)
        .then(({ data }) => {
          setREMMSData(data);
          setIsLoading(false);
        })
        .catch(error => {
          setIsLoading(false);
          setIsFailed(true);
        });
    }
  }, [dispatch]);

  return (
    <PlasmicRemmsComponent
      root={{ ref }}
      {...props}
      isLoading={isLoading}
      isFailed={isFailed}
      egidRow={{
        value: remmsData?.EGID,
        inputstate: remmsData?.EGIDState,
        objectId: objectId,
        cardId: 'GuW/Administratives'
      }}
      baujRow={{
        value: remmsData?.BAUJ,
        inputstate: remmsData?.BAUJState,
        objectId: objectId,
        cardId: getCardId('BAUJ', NUTZ!)
      }}
      baujtechRow={{
        value: remmsData?.BAUJTECH,
        inputstate: remmsData?.BAUJTECHState,
        hideIfEmpty: true,
        objectId: objectId,
        cardId: getCardId('BAUJTECH', NUTZ!)
      }}
      dachZustandLabelRow={{
        value: remmsData?.DACH_ZUSTAND_LABEL,
        inputstate: remmsData?.DACH_ZUSTAND_LABELState,
        objectId: objectId,
        cardId: 'GuW/Zustand'
      }}
      fassadeZustandLabelRow={{
        value: remmsData?.FASSADE_ZUSTAND_LABEL,
        inputstate: remmsData?.FASSADE_ZUSTAND_LABELState,
        objectId: objectId,
        cardId: 'GuW/Zustand'
      }}
      fensterZustandLabelRow={{
        value: remmsData?.FENSTER_ZUSTAND_LABEL,
        inputstate: remmsData?.FENSTER_ZUSTAND_LABELState,
        objectId: objectId,
        cardId: 'GuW/Zustand'
      }}
      gvM3Row={{
        value: remmsData?.GV_M3,
        inputstate: remmsData?.GV_M3State,
        hide: NUTZ !== 3,
        objectId: objectId,
        addonText: 'm<sup>3</sup>',
        cardId: 'GuW/Administratives'
      }}
      wohnfl={{
        value: remmsData?.WOHNFL,
        inputstate: remmsData?.WOHNFLState,
        hide: NUTZ === 3,
        objectId: objectId,
        cardId: 'GuW/Wohnung',
        hasAddon: true,
        addonText: 'm<sup>2</sup>'
      }}
      gebAnzahlGeschosseOberirdischRow={{
        value: remmsData?.GEB_ANZAHL_GESCHOSSE_OBERIRDISCH,
        inputstate: remmsData?.GEB_ANZAHL_GESCHOSSE_OBERIRDISCHState,
        objectId: objectId,
        cardId: getCardId('GEB_ANZAHL_GESCHOSSE_OBERIRDISCH', NUTZ!)
      }}
      gebkonRow={{
        value: remmsData?.GEBKON,
        inputstate: remmsData?.GEBKONState,
        objectId: objectId,
        cardId: getCardId('GEBKON', NUTZ!)
      }}
      dachcRow={{
        value: remmsData?.DACHC,
        inputstate: remmsData?.DACHCState,
        objectId: objectId,
        cardId: getCardId('DACHC', NUTZ!)
      }}
      energieLabelRow={{
        value: remmsData?.ENERGIE_LABEL,
        inputstate: remmsData?.ENERGIE_LABELState,
        objectId: objectId,
        cardId: 'GuW/Nachhaltigkeit'
      }}
      heatingsystemaCodeRow={{
        value: remmsData?.HEATINGSYSTEMA_CODE,
        inputstate: remmsData?.HEATINGSYSTEMA_CODEState,
        objectId: objectId,
        cardId: 'GuW/Nachhaltigkeit'
      }}
      hotwaterCodeRow={{
        value: remmsData?.HOTWATER_CODE,
        inputstate: remmsData?.HOTWATER_CODEState,
        objectId: objectId,
        cardId: 'GuW/Nachhaltigkeit'
      }}
      ebfM2Row={{
        value: remmsData?.EBF_M2,
        inputstate: remmsData?.EBF_M2State,
        objectId: objectId,
        cardId: 'GuW/Nachhaltigkeit',
        hasAddon: true,
        addonText: 'm<sup>2</sup>'
      }}
      labelGeakRow={{
        value: remmsData?.LABEL_GEAK,
        inputstate: remmsData?.LABEL_GEAKState,
        hideIfEmpty: true,
        objectId: objectId,
        cardId: 'GuW/Nachhaltigkeit'
      }}
      geakenergieettigesRow={{
        value: remmsData?.GEAKENERGIEETTIGES,
        inputstate: remmsData?.GEAKENERGIEETTIGESState,
        hideIfEmpty: true,
        objectId: objectId,
        cardId: 'GuW/Nachhaltigkeit'
      }}
      geakenergieettihueRow={{
        value: remmsData?.GEAKENERGIEETTIHUE,
        inputstate: remmsData?.GEAKENERGIEETTIHUEState,
        hideIfEmpty: true,
        objectId: objectId,
        cardId: 'GuW/Nachhaltigkeit'
      }}
      geakenergieettico2Row={{
        value: remmsData?.GEAKENERGIEETTICO2,
        inputstate: remmsData?.GEAKENERGIEETTICO2State,
        hideIfEmpty: true,
        objectId: objectId,
        cardId: 'GuW/Nachhaltigkeit'
      }}
      labelMinergiestandardRow={{
        value: remmsData?.LABEL_MINERGIESTANDARD,
        inputstate: remmsData?.LABEL_MINERGIESTANDARDState,
        hideIfEmpty: true,
        objectId: objectId,
        cardId: 'GuW/Nachhaltigkeit'
      }}
      remmsReportButton={{
        onClick: () => downloadReport(remmsData?.Id ?? undefined, setIsLoadingReport),
        isLoading: isLoadingReport
      }}
      r4AllButton={{
        onClick: () => window.open('https://app.remms.ch', '_blank', 'noreferrer')
      }}
      makroOekonomieRatingCard={{
        rating: remmsData?.MAKRO_OEKONOMIE ?? undefined
      }}
      mikroOekonomieRatingCard={{
        rating: remmsData?.MIKRO_OEKONOMIE ?? undefined
      }}
      objOekonomieRatingCard={{
        rating: remmsData?.OBJ_OEKONOMIE ?? undefined
      }}
      makroOekologieRatingCard={{
        rating: remmsData?.MAKRO_OEKOLOGIE ?? undefined
      }}
      mikroOekologieRatingCard={{
        rating: remmsData?.MIKRO_OEKOLOGIE ?? undefined
      }}
      objOekologieRatingCard={{
        rating: remmsData?.OBJ_OEKOLOGIE ?? undefined
      }}
      makroSozialesRatingCard={{
        rating: remmsData?.MAKRO_SOZIALES ?? undefined
      }}
      mikroSozialesRatingCard={{
        rating: remmsData?.MIKRO_SOZIALES ?? undefined
      }}
      sanGebhuelActionField={{
        nettoinvest: remmsData?.SAN_GEBHUEL_NETTOINVEST ?? undefined,
        co2: (remmsData?.SAN_GEBHUEL_WAERME_CO2 ?? 0) + (remmsData?.SAN_GEBHUEL_STROM_CO2 ?? 0),
        title: (remmsData?.SANIERUNG_TEXT_GEBHUELLE ?? undefined),
        hide:
          (remmsData?.SAN_GEBHUEL_WAERME_CO2 === 0 || remmsData?.SAN_GEBHUEL_WAERME_CO2 === null) &&
          (remmsData?.SAN_GEBHUEL_STROM_CO2 === 0 || remmsData?.SAN_GEBHUEL_STROM_CO2 === null)
      }}
      erstzWaermerzActionField={{
        nettoinvest: remmsData?.ERSTZ_WAERMERZ_NETTOINVEST ?? undefined,
        co2: (remmsData?.ERSTZ_WAERMERZ_WAERME_CO2 ?? 0) + (remmsData?.ERSTZ_WAERMERZ_STROM_CO2 ?? 0),
        title: (remmsData?.SANIERUNG_TEXT_WAERMEERZ ?? undefined),
        hide:
          (remmsData?.ERSTZ_WAERMERZ_WAERME_CO2 === 0 || remmsData?.ERSTZ_WAERMERZ_WAERME_CO2 === null) &&
          (remmsData?.ERSTZ_WAERMERZ_STROM_CO2 === 0 || remmsData?.ERSTZ_WAERMERZ_STROM_CO2 === null)
      }}
      einbaupvdachActionField={{
        nettoinvest: remmsData?.EINBAUPVDACH_NETTOINVEST ?? undefined,
        co2: (remmsData?.EINBAUPVDACH_STROM_CO2 ?? 0) + (remmsData?.EINBAUPVDACH_WAERME_CO2 ?? 0),
        title: (remmsData?.SANIERUNG_TEXT_PV ?? undefined),
        noAction:
          (remmsData?.SAN_GEBHUEL_WAERME_CO2 === 0 || remmsData?.SAN_GEBHUEL_WAERME_CO2 === null) &&
          (remmsData?.SAN_GEBHUEL_STROM_CO2 === 0 || remmsData?.SAN_GEBHUEL_STROM_CO2 === null) &&
          (remmsData?.ERSTZ_WAERMERZ_WAERME_CO2 === 0 || remmsData?.ERSTZ_WAERMERZ_WAERME_CO2 === null) &&
          (remmsData?.ERSTZ_WAERMERZ_STROM_CO2 === 0 || remmsData?.ERSTZ_WAERMERZ_STROM_CO2 === null) &&
          (remmsData?.EINBAUPVDACH_WAERME_CO2 === 0 || remmsData?.EINBAUPVDACH_WAERME_CO2 === null) &&
          (remmsData?.EINBAUPVDACH_STROM_CO2 === 0 || remmsData?.EINBAUPVDACH_STROM_CO2 === null)
      }}
      handlungsFelderListe={constructHandlungsfelder(remmsData)}
      enrEntrUnbeknVerbRow={{
        value: remmsData?.ENR_ENTR_UNBEKN_VERB,
        addonText: remmsData?.ENR_ENTR_UNBEKN_EINH?.toString() ?? '',
        inputstate: remmsData?.ENR_ENTR_UNBEKN_VERB ? 1 : 0,
        hideIfEmpty: true,
        objectId: objectId,
        cardId: 'GuW/Nachhaltigkeit'
      }}
      enrEntrWpVerbRow2={{
        value: remmsData?.ENR_ENTR_WP_VERB,
        addonText: remmsData?.ENR_ENTR_WP_EINH?.toString() ?? '',
        inputstate: remmsData?.ENR_ENTR_WP_VERB ? 1 : 0,
        hideIfEmpty: true,
        objectId: objectId,
        cardId: 'GuW/Nachhaltigkeit'
      }}
      enrEntrGasVerbRow={{
        value: remmsData?.ENR_ENTR_GAS_VERB,
        addonText: remmsData?.ENR_ENTR_GAS_EINH?.toString() ?? '',
        inputstate: remmsData?.ENR_ENTR_GAS_VERB ? 1 : 0,
        hideIfEmpty: true,
        objectId: objectId,
        cardId: 'GuW/Nachhaltigkeit'
      }}
      enrEntrOelVerbRow={{
        value: remmsData?.ENR_ENTR_OEL_VERB,
        addonText: remmsData?.ENR_ENTR_OEL_EINH?.toString() ?? '',
        inputstate: remmsData?.ENR_ENTR_OEL_VERB ? 1 : 0,
        hideIfEmpty: true,
        objectId: objectId,
        cardId: 'GuW/Nachhaltigkeit'
      }}
      enrEntrHlzVerbRow={{
        value: remmsData?.ENR_ENTR_HLZ_VERB,
        addonText: remmsData?.ENR_ENTR_HLZ_EINH?.toString() ?? '',
        inputstate: remmsData?.ENR_ENTR_HLZ_VERB ? 1 : 0,
        hideIfEmpty: true,
        objectId: objectId,
        cardId: 'GuW/Nachhaltigkeit'
      }}
      enrEntrElekhzVerbRow={{
        value: remmsData?.ENR_ENTR_ELEKHZ_VERB,
        addonText: remmsData?.ENR_ENTR_ELEKHZ_EINH?.toString() ?? '',
        inputstate: remmsData?.ENR_ENTR_ELEKHZ_VERB ? 1 : 0,
        hideIfEmpty: true,
        objectId: objectId,
        cardId: 'GuW/Nachhaltigkeit'
      }}
      enrEntrSoltVerbRow={{
        value: remmsData?.ENR_ENTR_SOLT_VERB,
        addonText: remmsData?.ENR_ENTR_SOLT_EINH?.toString() ?? '',
        inputstate: remmsData?.ENR_ENTR_SOLT_VERB ? 1 : 0,
        hideIfEmpty: true,
        objectId: objectId,
        cardId: 'GuW/Nachhaltigkeit'
      }}
      enrEntrFwaeVerbRow={{
        value: remmsData?.ENR_ENTR_FWAE_VERB,
        addonText: remmsData?.ENR_ENTR_FWAE_EINH?.toString() ?? '',
        inputstate: remmsData?.ENR_ENTR_FWAE_VERB ? 1 : 0,
        hideIfEmpty: true,
        objectId: objectId,
        cardId: 'GuW/Nachhaltigkeit'
      }}
    />
  );
}

const RemmsComponent = React.forwardRef(RemmsComponent_);
export default RemmsComponent;
